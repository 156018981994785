import React, { useEffect, useState } from "react";
import useDarkMode from "../../../Profile/usedarkmode";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Toaster from "../../../../components/reusable/Toaster";
import { useUser } from "../../../../utils/contexts/UserContext";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";

interface CategoriesSetupProps {
  onPrevious?: () => void;
  onSubmit?: () => void;
}

const SaftSetup: React.FC<CategoriesSetupProps> = ({
  onPrevious,
  onSubmit,
}) => {
  const axiosPrivate = useAxiosPrivate();

  // State Variables
  const { user } = useUser();
  const [changedList, setChangedList] = useState(false);
  const [descriptive, setDescriptive] = useState("");
  const [exclude, setExclude] = useState("");
  const [value, setValue] = useState(0);

  useEffect(() => {
    let isMounted = true;

    const controller = new AbortController();
    const getRules = async (endpoint: string) => {
      try {
        const response = await axiosPrivate.get(endpoint, {
          signal: controller.signal,
        });

        const data = response.data;
        setDescriptive(
          data.find((rule) => rule.sales_dt_rule_type === "MAP-IDSITE") ?? ""
        );
        setExclude(
          data.find((rule) => rule.sales_dt_rule_type === "EXCLUDE") ?? ""
        );
        setValue(
          data.find((rule) => rule.sales_dt_rule_type === "DAY-START" ?? "")
            ?.sales_dt_rule_value
        );
      } catch (error) {
        console.error(error);
      }
    };
    getRules("saft-rules-list");

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [changedList]);

  const handleSubmit = async () => {
    const rules = [
      {
        saft_rule_id: descriptive ? descriptive["saft_rule_id"] : 0,
        sales_dt_rule_name: "Associa site ao descritivo do SAFT",
        sales_dt_rule_tag: descriptive || "default_tag",
        sales_dt_rule_target: "companyid",
        sales_dt_rule_type: "MAP-IDSITE",
        sales_dt_rule_value: 0,
      },
      {
        saft_rule_id: exclude ? exclude["saft_rule_id"] : 0,
        sales_dt_rule_name: "Excluir facturas com prodcode",
        sales_dt_rule_tag: exclude || "default_tag",
        sales_dt_rule_target: "productcode",
        sales_dt_rule_type: "EXCLUDE",
        sales_dt_rule_value: 0,
      },
      {
        saft_rule_id: value ? value["saft_rule_id"] : 0,
        sales_dt_rule_name: "Definir inicio do dia",
        sales_dt_rule_tag: "n/d",
        sales_dt_rule_target: "systementrydate",
        sales_dt_rule_type: "DAY-START",
        sales_dt_rule_value: value !== undefined ? value : 0,
      },
    ];

    try {
      if (onSubmit) {
        onSubmit();
      }
      const responses = await Promise.all(
        rules.map((rule) =>
          axiosPrivate.post("/create-update-saft-rules/", JSON.stringify(rule))
        )
      );

      const allSuccessful = responses.every(
        (response) => response.status === 200 || response.status === 201
      );

      if (allSuccessful) {
        Toaster.show("Regras atualizadas com sucesso.", "success");
        if (onSubmit) {
          onSubmit();
        }
        setChangedList(!changedList);
      } else {
        Toaster.show("Erro ao atualizar regras.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao atualizar regras.", "error");
    }
  };

  const handleApplyRules = async () => {
    try {
      const response = await axiosPrivate.post("/apply_saft_rules/");

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Regras aplicadas com sucesso.", "success");
      } else {
        Toaster.show("Erro ao aplicar regras.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao aplicar regras.", "error");
    }
  };

  const darkMode = useDarkMode();

  return (
    <div className="">
      <div className="flex justify-between px-2">
        {user.profile && (
          <Button
            onClick={handleApplyRules}
            style={{ background: darkMode ? "#422655" : "" }}
            className="text-white w-52 bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
          >
            <p>Reprocessar dados e Aplicar Regras</p>
          </Button>
        )}
      </div>
      <div className="mt-5">
        <InfoComponent text="Text de ajuda...">
          <div>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>Regra de Mapeamento</span>
              </strong>
              <span style={{ fontSize: "11pt" }}>&nbsp;</span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                Pressup&otilde;e-se que cada unidade de neg&oacute;cios da
                entidade jur&iacute;dica a que os dados dizem respeito tem um
                descritivo &uacute;nico e esse &eacute; usado para associar os
                dados com o Restaurante cliente definido na
                aplica&ccedil;&atilde;o.&nbsp;
              </span>
            </p>
          </div>
        </InfoComponent>
        <div className="mt-2">
          <Input
            name="descriptive"
            placeholder={descriptive ? descriptive["sales_dt_rule_tag"] : ""}
            value={descriptive}
            onChange={(e) => setDescriptive(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-10">
        <InfoComponent text="Text de ajuda...">
          <div>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>
                  Regra de Filtro de Produtos
                </span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                Permite que a mesma empresa facture diferentes servi&ccedil;os e
                produtos e que apenas os ligados &agrave;
                restaura&ccedil;&atilde;o sejam considerados para
                an&aacute;lise. O utilizador deve indicar um descritivo e a tag
                a usar para filtrar os dados. Serão excluidas faturas com o
                prodcode SAFT com o valor indicado.
              </span>
            </p>
          </div>
        </InfoComponent>
        <div className="mt-2">
          <Input
            name="exclude"
            placeholder={exclude ? exclude["sales_dt_rule_tag"] : ""}
            value={exclude}
            onChange={(e) => setExclude(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-10">
        <InfoComponent text="Text de ajuda...">
          <div>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>
                  Regra de Ajuste de data refer&ecirc;ncia&nbsp;
                </span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                Neste tipo de regra o utilizador pode escolher a mudan&ccedil;a
                de dia para efeitos de an&aacute;lise das vendas o que significa
                que positivos ir&atilde;o prolongar o dia em X horas enquanto
                valores negativos ir&atilde;o antecipar o dia em X horas.&nbsp;
              </span>
            </p>
          </div>
        </InfoComponent>
        <div className="mt-2">
          <select
            className="flex bg-white h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            value={value}
            onChange={(e) => setValue(Number(e.target.value))}
          >
            {[-8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8].map(
              (hour) => (
                <option
                  key={hour}
                  value={hour}
                  className="py-2"
                >{`${hour} horas`}</option>
              )
            )}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>

      <div className="flex justify-between mb-2 mt-5">
        {onPrevious && <Button onClick={onPrevious}>Anterior</Button>}
        <Button className="ml-auto" onClick={handleSubmit}>
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default SaftSetup;
