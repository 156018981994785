// React
import React, { useEffect, useState } from "react";


// contexts
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useLoading } from "../../../utils/contexts/LoadingContext";

// components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";
import InfoComponent from "../../../components/reusable/info/info.component";
import Toaster from "../../../components/reusable/Toaster";

// icons / images
import { IoCart } from "react-icons/io5";
import { exportToPDF, Table, TableBody, TableCell, TableHeader, TableRow } from "../../../components/ui/table";
import { IoIosSearch } from "react-icons/io";
import { FaDownload } from "react-icons/fa";


const Planning = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "PLANNING PAGE",
  });

  const { get } = useApi();
  const darkMode = useDarkMode();

  const [tableData, setTableData] = useState({
    q1: null,
    q2: null,
    q3: null,
    q4: null,
  });

  const [tableDataFilter, setTableDataFilter] = useState({
    q1: null,
    q2: null,
    q3: null,
    q4: null,
  });

  const [lastSalesDate, setLastSalesDate] = useState(null);
  const { loading, setLoading } = useLoading();
  const [searchQueries, setSearchQueries] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
  });

  const titles = {
    q1: "Quantidade de doses a considerar na estimativa de compras",
    q2: "Quantidades usadas nas receitas - Valores médios de PVP e estimativa de custo",
    q3: "Ingredientes - Por SKU de referência",
    q4: "Ingredientes usados em receitas - Os 3 PVP's mais baixos",
  };

  const columnDefinitions = {
    q1: [
      { headerName: "Receita", field: "receita" },
      { headerName: "Descrição", field: "rest_prod_desc" },
      { headerName: "Doses", field: "doses" },
    ],
    q2: [
      { headerName: "Ingrediente", field: "ingr_name" },
      { headerName: "Quantidade(kg)", field: "qt_ingr_kg" },
      { headerName: "Preço médio", field: "price_avg" },
      { headerName: "Custo Estimado", field: "custo_estimado" },
    ],
    q3: [
      { headerName: "Ingrediente", field: "ingr_name" },
      { headerName: "Quantidade(kg)", field: "qt_ingr_kg" },
      { headerName: "Detalhes", field: "sku_prod_name" },
      { headerName: "Fornecedor", field: "sku_prov_name" },
      { headerName: "Categoria", field: "sku_category" },
      { headerName: "Preço", field: "price" },
      { headerName: "Data do Preço", field: "price_date" },
      { headerName: "Custo Estimado", field: "custo_estimado" },
    ],
    q4: [
      { headerName: "Ingrediente", field: "ingr_name" },
      { headerName: "Quantidade(kg)", field: "qt_ingr_kg" },
      { headerName: "Detalhes", field: "sku_prod_name" },
      { headerName: "Fornecedor", field: "sku_prov_name" },
      { headerName: "Categoria", field: "sku_category" },
      { headerName: "Preço", field: "price" },
      { headerName: "Data do Preço", field: "price_date" },
      { headerName: "Custo Estimado", field: "custo_estimado" },
      { headerName: "Rank", field: "rank" },
    ],
  };




  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const endpoints = [
          "dash4_sales_last_date",
          "d9_q1",
          "d9_q2",
          "d9_q3",
          "d9_q4",
        ];
        const results = await Promise.allSettled(
          endpoints.map((endpoint) =>
            get(endpoint, { signal: controller.signal })
          )
        );

        let errorMessages = "Não existem dados para:\n";

        results.forEach((result, index) => {
          if (result.status === "fulfilled") {
            if (index === 0) {
              setLastSalesDate(result.value.data);
            } else {
              const key = `q${index}`;
              if (result.value.data.length === 0) {
                errorMessages += `-> ${titles[key]}\n`;
              }
              setTableData((prev) => ({ ...prev, [key]: result.value.data }));
              setTableDataFilter((prev) => ({ ...prev, [key]: result.value.data }));
            }
          } else {
            console.error(`Error fetching ${endpoints[index]}:`, result.reason);
            if (index !== 0) {
              const key = `q${index}`;
              setTableData((prev) => ({ ...prev, [key]: null }));
              setTableDataFilter((prev) => ({ ...prev, [key]: null }));
            }
            errorMessages += `-> ${titles[`q${index}`]}\n`;
          }
        });
        if (errorMessages !== "Não existem dados para:\n") {
          Toaster.show(errorMessages.slice(0, -1), "error"); // Remove last \n\n
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
        Toaster.show("Erro ao carregar dados", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const updateTableDataFilter = (key) => {
      if (!tableData || !tableData[key]) return;

      switch (key) {
        case "q1":
          setTableDataFilter((prev) => ({
            ...prev,
            q1: tableData.q1.filter((item) =>
              item.receita.toLowerCase().includes(searchQueries.q1.toLowerCase())
            ),
          }));
          break;
        case "q2":
          setTableDataFilter((prev) => ({
            ...prev,
            q2: tableData.q2.filter((item) =>
              item.ingr_name.toLowerCase().includes(searchQueries.q2.toLowerCase())
            ),
          }));
          break;
        case "q3":
          setTableDataFilter((prev) => ({
            ...prev,
            q3: tableData.q3.filter((item) =>
              item.ingr_name.toLowerCase().includes(searchQueries.q3.toLowerCase())
            ),
          }));
          break;
        case "q4":
          setTableDataFilter((prev) => ({
            ...prev,
            q4: tableData.q4.filter((item) =>
              item.ingr_name.toLowerCase().includes(searchQueries.q4.toLowerCase())
            ),
          }));
          break;
        default:
          break;
      }
    };

    Object.keys(searchQueries).forEach((key) => {
      updateTableDataFilter(key);
    });
  }, [searchQueries, tableData]);

  const renderSearchBar = (key) => (

    <div className="bg-gray-200 rounded-full shadow flex px-2 py-1 w-fit">
      <input
        type="text"
        name="search"
        className="w-60 px-4 transition-all duration-300 ease-in-out dark:text-gray-800 outline-none bg-transparent"
        value={searchQueries[key]}
        onChange={(e) => setSearchQueries((prev) => ({ ...prev, [key]: e.target.value }))}
      />
      <button type="submit">
        <div>
          <IoIosSearch className="text-zinc-500" size={36} />
        </div>
      </button>
    </div>
  );

  const renderTable = (key) => (
    <div >
      <h3 className="text-2xl font-semibold text-blue-dark">{titles[key]}</h3>
      <hr className="my-4" />
      <div className="pb-4 flex gap-2 justify-between items-center">
        {renderSearchBar(key)}
        <FaDownload className="cursor-pointer" onClick={() => exportToPDF(columnDefinitions[key], tableDataFilter[key], titles[key])} size={24} />
      </div>
      <div className="overflow-x-auto">
        {tableDataFilter[key] && <Table className="min-w-full">
          {renderTableHeader(
            columnDefinitions[key].map((col) => col.headerName)
          )}
          {renderTableBody(
            columnDefinitions[key].map((col) => col.field),
            tableDataFilter[key]
          )}
        </Table>}
      </div>

    </div>
  );

  const renderTableHeader = (columnNames: string[]) => (
    <TableHeader>
      <TableRow>
        {columnNames.map((header) => (
          <TableCell
            key={header}
            style={{ background: darkMode ? "#422655" : "" }}
            className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
          >
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHeader>
  );

  const renderTableBody = (fields: string[], data: any[]) => (
    <TableBody
      style={{ backgroundColor: darkMode ? "#262853" : "" }}
      className="bg-white divide-y divide-gray-200"
    >
      {data.map((item, index) => {
        const getBackgroundColor = (rank) => {
          switch (rank) {
            case 1:
              return "rgba(144, 238, 144, 0.5)";
            case 2:
              return "rgba(173, 255, 47, 0.5)";
            case 3:
              return "rgba(255, 238, 140, 0.5)";
            default:
              return "";
          }
        };

        return (
          <TableRow
            key={index}
            style={{ backgroundColor: getBackgroundColor(item.rank) }}
          >
            {fields.map((field, fieldIndex) => {
              let value = item[field];
              if (
                (field.includes("price") || field.includes("custo")) &&
                !field.includes("date")
              ) {
                value = `${Number(value).toFixed(2)}€`;
              }
              return (
                <TableCell
                  key={fieldIndex}
                  className="px-6 py-2 whitespace-no-wrap text-gray-600"
                >
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );

  return (
    <Page title={"Planeamento de Compras"} loading={loading}>
      {tokenVerified ? (
        <div>
          <div>
            <div>
              <div className="w-full padding">
                {lastSalesDate && (
                  <InfoComponent>
                    <p>
                      Dados disponíveis até a data:{" "}
                      {new Date(lastSalesDate).toLocaleDateString("pt-PT", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </p>
                  </InfoComponent>
                )}
              </div>
              <div style={{ paddingBottom: "100px" }} className="flex gap-8 flex-col">
                {Object.keys(tableData).map((key) => (
                  <React.Fragment key={key}>
                    {tableData[key] &&
                      tableData[key].length > 0 &&
                      renderTable(key)}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
<></>
      )}
    </Page>
  );
};

export default Planning;
