class KpiFormatter {
  static kpi = ["quantity", "total_sales", "numero_faturas"];
  static default = "quantity";

  static format(input: string) {
    if (this.kpi.includes(input)) {
      if (input === "quantity") {
        return "Itens Vendidos";
      } else if (input === "total_sales") {
        return "Total de Vendas";
      } else if (input === "numero_faturas") {
        return "Número de Faturas";
      }
    } else {
      return null;
    }
  }

  static getAll() {
    return this.kpi;
  }
}

export default KpiFormatter;
