// React
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// contexts
import { DarkModeProvider } from "../../../../utils/contexts/theme.context";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

// components
import Page from "../../page";
import Loading from "../../../../components/reusable/loading/loading";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../../Profile/usedarkmode";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import EventsCreate from "./EventsCreate";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";

// icons / images
import { IoCart } from "react-icons/io5";
import { MdSearch } from "react-icons/md";

// interface Event {
//   an_id: number;
//   an_kpi_agre_type: string;
//   an_kpi_desc: string;
//   an_kpi_dt_ref_info: string;
//   an_kpi_filter_field_info: string;
//   an_kpi_filter_identif_info: string;
//   an_kpi_id: number;
//   an_kpi_name: string;
//   an_kpi_num_week_range_info: number;
//   an_kpi_qualit_type: string;
//   an_kpi_source_info: string;
//   an_kpi_time_field_info: string;
//   an_kpi_value_info: string;
//   zdata: string;
// }

interface EventListProps {
  an_id: number;
  an_nome: string;
  an_descr: string;
  an_obs: string;
  zdata: string;
  an_dt_fim: string;
  an_dt_ini: string;
  an_filter1: string;  //KPI_AGREGADO OU GRANULAR
  an_filter2: string;
  an_filter3: string;
  an_filter4: string;

}

const EventsList = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const navigate = useNavigate();

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "CREATE EVENTS",
  });

  const { get } = useApi();
  const [events, setEvents] = useState<EventListProps[]>([]);
  const { loading, setLoading } = useLoading();

  const darkMode = useDarkMode();

  const fetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await get("list_events");
      console.log("events", response.data);
      setEvents(response.data);
    } catch (error) {
      console.error(error);
      Toaster.show("Erro ao carregar listas de eventeamento", "error");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const renderEventTable = () => (
    <Table>
      <TableHeader>
        <TableRow>
          {[
            "Nome",
            "Data de Criação",
            "Descritivo",
            // "Tipo",
            "Data do Evento",
            "Semanas a Considerar",
            "Estado",
          ].map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {events.map((item, index) => (
          <TableRow
            key={index}
          >
            <TableCell className="text-gray-600">{item.an_nome}</TableCell>
            <TableCell className="text-gray-600">
              {item.zdata.substring(0, 16)}
            </TableCell>
            <TableCell className="text-gray-600">{item.an_descr}</TableCell>
            {/* <TableCell className="text-gray-600">
              {item.an_filter1}
            </TableCell> */}
            <TableCell className="text-gray-600">
              {item.an_dt_ini}
            </TableCell>
            {/* <TableCell className="text-gray-600">
              {item.an_filter3}
            </TableCell> */}
            <TableCell className="text-gray-600">
              {item.an_filter1 !== "" ? `${item.an_filter1} semanas` : ""}
            </TableCell>
            <TableCell>
              {item.an_obs.includes("Disponível") ? <Button
                className="cursor-pointer bg-transparent hover:bg-zinc-200"
                onClick={() => navigate(`/app/events/detail/${item.an_id}`)}
              >
                <MdSearch title="Ver Análise" color="black" size={22} />
              </Button>
              : <div>Em Processamento</div>}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Page title={"Criar Eventos"} loading={loading}>
      {tokenVerified ? (
        <DarkModeProvider>
          <div className="w-[80%] mx-auto">
            <div className="flex justify-between items-center mb-5">
              <CardHeader>
                <CardTitle>Eventos</CardTitle>
                <CardDescription>
                  Crie eventos para analisar o seu negócio
                </CardDescription>
              </CardHeader>
              <Dialog>
                <DialogTrigger asChild>
                  <Button className="mt-5">+ Criar Evento</Button>
                </DialogTrigger>
                <DialogContent className="min-w-[90%] xl:min-w-[1000px] max-h-[90%] overflow-auto">
                  <DialogTitle>Criar Novo Evento</DialogTitle>
                  <DialogDescription>
                    Preencha os campos para criar um novo evento
                  </DialogDescription>
                  <EventsCreate />
                </DialogContent>
              </Dialog>
            </div>
            <div>
              {events.length > 0 ? (
                renderEventTable()
              ) : (
                <div
                  style={{ color: darkMode ? "white" : "black" }}
                  className="text-center text-2xl mt-10"
                >
                  Sem eventos. Crie um novo evento.
                </div>
              )}
            </div>
            <Loading isOpen={loading} />
          </div>
        </DarkModeProvider>
      ) : (
        // <Loading isOpen={true} />
<></>
      )}
    </Page>
  );
};

export default EventsList;
