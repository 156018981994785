class LabelFormatter {
  labels: any[];
  default: string;

  constructor(labels: any[]) {
    this.labels = labels;
    this.default = this.labels[0] || ""; // Assigning the first label, or empty string if labels array is empty
  }

  format(input: string): string {
    return input;
  }

  getAll(): any[] {
    return this.labels;
  }
}

export default LabelFormatter;
