import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
//const BASE_URL = 'https://webapp.ai4chef.com/api/';

axios.defaults.withCredentials = true;

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
