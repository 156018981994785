import React from "react";
import { useNavigate } from "react-router-dom";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import { IoArrowBackCircleOutline } from "react-icons/io5";

const BackComponent = () => {
  const darkMode = useDarkMode();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="py-4 flex gap-2 items-center cursor-pointer w-fit" onClick={goBack}>
      <IoArrowBackCircleOutline
        size={32}
        title="Voltar"
        className="goBackIcon"
        style={{ color: darkMode ? "white" : "black" }}
      />
      <p className="text-xl">Voltar</p>
    </div>
  );
};

export default BackComponent;
