// React
import { useDarkMode } from "../../../../utils/contexts/theme.context";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Page from "../../page";
import BackComponent from "../../../../components/reusable/goBack/go-back-component";
import ChartComponent from "../../../../components/reusable/charts/chartComponent";
import SelectComponent from "../../../../components/reusable/select/select";
import LabelFormatter from "../../../../components/reusable/select/label";
import ChartFunc from "../../../../components/reusable/charts/chartFunc";
import Loading from "../../../../components/reusable/loading/loading";
import ProdFormatter from "../../../../components/reusable/select/num_prod";

import { useLoading } from "../../../../utils/contexts/LoadingContext";



const EventsView = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "Events VIEW PAGE",
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventN = queryParams.get("id");
  const [label, setLabel] = useState("Melhor");
  const [analysis, setAnalysis] = useState({});
  const [num_prod, setNumProd] = useState(3);
  const axiosPrivate = useAxiosPrivate();
  const { loading, setLoading } = useLoading();
  const [eventCharts, seteventCharts] = useState({
    chart4: { option: {}, func: "", drilldown_data: "" },
    chart5: { option: {}, func: "", drilldown_data: "" },
    rfmClasses: ["loading..."],
  });
  const chartRef = useRef(null);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    // Get analysis details
    const getAnalysisDetail = async () => {
      try {
        const response = await axiosPrivate.get(
          `analysis_detail/${eventN}`,
          {
            signal: controller.signal,
          }
        );
        setAnalysis(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    // Get analysis charts
    const getCharts = async (endpoint, params = {}) => {
      console.log(
        "getCharts called with endpoint:",
        endpoint,
        "and params:",
        params
      );
      const url = params
        ? `${endpoint}/?${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`
        : `${endpoint}`;

      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });
        console.log("getCharts response:", response.data);
        seteventCharts(response.data);
        if (response.data["rfmClasses"] && eventCharts.rfmClasses.includes("loading..."))
          setLabel(response.data["rfmClasses"][0]);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    // Run
    Promise.all([
      getAnalysisDetail(),
      getCharts("event_analysis_charts", {
        an_id: eventN,
        label: label,
        num_prod: num_prod,
      }),
    ]);

    return () => {
      controller.abort();
    };
  }, [axiosPrivate, eventN, label, num_prod]);

  const darkMode = useDarkMode();

  return (
    <Page
    loading={loading}
    >
      {tokenVerified ? (
        <div>
          <div
            style={{ paddingBottom: "20px", paddingTop: "10px" }}
            className="padding"
          >
            <BackComponent />
            <div className="bg-white overflow-hidden rounded-lg">
              <div className="md:flex">
                <div className="md:w-1/2 p-8">
                  <div className="uppercase tracking-wide text-sm text-gray-700 font-semibold">
                    {analysis["an_nome"]}
                  </div>
                  <p className="bg-gray-100 mt-2 text-gray-600 p-4 rounded-lg">
                    {analysis["an_descr"]}
                  </p>
                </div>
                <div className="md:w-1/2 p-8 text-sm">
                  <div className="grid grid-cols-2 mb-3">
                    <p className="text-gray-500 font-bold">Data de início</p>
                    <p className="text-gray-500 text-right">
                      {analysis["an_dt_ini"]}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 mb-3">
                    <p className="text-gray-500 font-bold">Data de fim</p>
                    <p className="text-gray-500 text-right">
                      {analysis["an_dt_fim"]}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 mb-3">
                    <p className="text-gray-500 font-bold">Status</p>
                    <p className="text-gray-500 text-right">
                      {analysis["an_obs"]}
                    </p>
                  </div>
                  <div className="grid grid-cols-2">
                    <p className="text-gray-500 font-bold">Criado em</p>
                    <p className="text-gray-500 text-right">
                      {analysis["zdata"]}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid xl:grid-cols-2 gap-4 padding py-6">
              <ChartComponent
                chartRef={chartRef}
                option={eventCharts["chart1"]}
                ChangeScale={false}
              />
              <ChartComponent
                chartRef={chartRef}
                option={eventCharts["chart2"]}
                ChangeScale={false}
              />
            </div>
            <div className="flex gap-8 py-6">
              <SelectComponent
                formatter={new LabelFormatter(eventCharts["rfmClasses"])}
                darkMode={darkMode}
                onChange={setLabel}
              />
              <SelectComponent
                formatter={ProdFormatter}
                darkMode={darkMode}
                onChange={setNumProd}
              />
            </div>
            <div className="grid xl:grid-cols-2 gap-4 padding py-6">
              <ChartComponent
                chartRef={chartRef}
                option={eventCharts["chart3"]}
                ChangeScale={false}
              />
              <ChartFunc
                chartRef={chartRef}
                option={eventCharts["chart4"]["option"]}
                func={eventCharts["chart4"]["func"]}
                drilldown_data={eventCharts["chart4"]["drilldown_data"]}
              />
              <ChartFunc
                chartRef={chartRef}
                option={eventCharts["chart5"]["option"]}
                func={eventCharts["chart5"]["func"]}
                drilldown_data={eventCharts["chart5"]["drilldown_data"]}
              />
            </div>
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
<></>
      )}
    </Page>
  );
};

export default EventsView;
