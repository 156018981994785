import { axiosPrivate } from "../../../utils/api/axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PricingTable = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log("Fetching products");
        const response = await axiosPrivate.get("/get-stripe-products");
        console.log("Response:", response);
        const data = response.data;
        console.log(data);
        setProducts(data); // Assuming the response is an array of products
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []); // Empty dependency

  return (
    <div className="flex items-center justify-center w-full">
      <div className="font-[sans-serif] py-20">
        <div className="max-w-5xl max-lg:max-w-3xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-2 text-gray-800">
              Escolha o seu plano e melhore a sua cozinha!
            </h2>
            <p className="text-sm text-gray-500">
              Veja a nossa oferta de planos e escolha o que melhor atende às
              suas necessidades
            </p>
          </div>

          <div className="grid lg:grid-cols-2 sm:grid-cols-2 gap-6 max-sm:max-w-sm max-sm:mx-auto mt-12">
            {products.map((product) => (
              <div
                key={product.id}
                className="bg-white shadow rounded-3xl p-6 hover:scale-105 transition-all duration-300 border border-gray-200"
              >
                <h4 className="text-gray-800 text-lg mb-3">{product.name}</h4>
                <h3 className="text-4xl font-semibold ">
                  {product.price}€
                  <sub className="text-gray-500 font-medium text-sm ml-1">
                    / month
                  </sub>
                </h3>

                <hr className="my-6 border-gray-300" />

                <div>
                  <ul className="space-y-4">
                    {product.marketing_features.map((feature, index) => (
                      <li
                        key={index}
                        className="flex items-center text-sm text-gray-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          className="mr-3 bg-gray-200 fill-black rounded-full p-[3px]"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                            data-original="#000000"
                          />
                        </svg>
                        {feature.name}
                      </li>
                    ))}
                  </ul>

                  <button
                    type="button"
                    className="w-full mt-6 px-4 py-2 text-sm tracking-wide bg-black hover:bg-gray-700 text-white rounded-xl"
                    onClick={() => navigate(`/payment/${product.id}`)}
                  >
                    Começar
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
