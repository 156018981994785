class ClasseFormatter {
  static Classes = ["Select a Classe"];

  static default = "Select a Classe";

  static format(input: string): string {
    if (typeof input !== "string" || input === "") {
      return input;
    }
    const capitalized = input
      .split("")
      .map((char, index) => (index === 0 ? char.toUpperCase() : char))
      .join("");
    const replaced = capitalized.replace(/_/g, " e ");
    return replaced;
  }

  static getAll() {
    return this.Classes;
  }

  static setAll(Classes) {
    this.Classes = Classes;
  }
}
export default ClasseFormatter;
