import axiosPrivate from "../api/axios";
import useAuth from './useAuth';

const useRefreshToken = () => {
  let isRefreshing = false;
  let refreshPromise = null;
  const { auth, setAuth } = useAuth();
 
  const refresh = async () => {
    try {
      const response = await axiosPrivate.post("/refresh", {});
      setAuth({ accessToken: response.data.access });
      // Resolve all pending promises with the new access token
      while (pendingRequests.length > 0) {
        const request = pendingRequests.shift();
        request.resolve(response.data.access);
      }
      isRefreshing = false;
      return response.data.access;
    } catch (error) {
      // Handle refresh error (e.g., redirect to login)
      while (pendingRequests.length > 0) {
        const request = pendingRequests.shift();
        if (error.response && error.response.status === 401) {
          request.reject(new Error("Unauthorized"));
        } else {
          request.reject(error);
        }
      }
      isRefreshing = false;
      //throw new Error('Unauthorized');
    } finally {
      isRefreshing = false;
    }
  };

  const pendingRequests = [];

  const queueRequest = async () => {
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        refreshPromise = refresh();
      } catch (error) {
        console.log("Error refreshing token (Queue request): ", error);
      }
    }

    return new Promise((resolve, reject) => {
      // Queue the request to resolve when the refresh is complete
      pendingRequests.push({ resolve, reject });
    });
  };

  const refreshToken = async () => {
    try {
      // Call queueRequest to start the refresh process if not already in progress
      await queueRequest();

      // Wait for the refresh to complete and return the response
      const response = await refreshPromise;
      return response;
    } catch (error) {
      // Handle refresh error (e.g., redirect to login)
      console.log("Error refreshing token: (RefreshToken)", error);
      throw error; // isto talvez possa ser comentado
    }
  };

  return refreshToken;
};

export default useRefreshToken;
