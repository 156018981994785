import { useEffect, useState } from 'react';

const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(document.body.classList.contains("dark"));

  useEffect(() => {
    const observer = new MutationObserver(mutationsList => {
      if (document.body.classList.contains('dark')) {
        // Dark mode is enabled
        setIsDarkMode(true);
      } else {
        // Dark mode is disabled
        setIsDarkMode(false);
      }
    });

    observer.observe(document.body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return isDarkMode;
};

export default useDarkMode;
