// React
import { useEffect, useState } from "react";

// Hooks
import useDarkMode from "../../../Profile/usedarkmode";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import { useCat } from "../../../../utils/contexts/ClassContext";

// Components
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import SelectComponent from "../../../../components/reusable/select/select";
import ClasseFormatter from "../../../../components/reusable/select/classes";
import RecipeStatus from "../../../../components/reusable/select/recipeStatus";
import RecipeUsage from "../../../../components/reusable/select/recipeUsage";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import { Label } from "../../../../components/ui/label";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

const RecipeForm = ({ recipe_id = 0, modalCloser, change, setChange }) => {
  const classes = useCat();

  const axiosPrivate = useAxiosPrivate();
  const [recipeName, setRecipeName] = useState("");
  const [recipeDescription, setRecipeDescription] = useState("");
  const [recipeClass, setRecipeClass] = useState("");
  const [recipeIntructions, setRecipeIntructions] = useState("");
  const [recipeComments, setRecipeComments] = useState("");
  const [recipeRecursive, setRecipeRecursive] = useState("s");
  const [numberDoses, setNumberDoses] = useState(1);
  const [order, setOrder] = useState(0);
  const [recipeStatus, setRecipeStatus] = useState("");
  const [recipeUsage, setRecipeUsage] = useState("I");
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    console.log("Classes, ", classes);
    if (classes.Classes.length > 0) {
      setRecipeClass(classes.Classes[0]);
    }
  }, [classes]);



  useEffect(() => {
    if (recipe_id !== 0) {
      // Edit recipe
      const getRecipe = async (endpoint, params = {}) => {
        setLoading(true);
        const url = params
          ? `${endpoint}?${Object.entries(params)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")}`
          : `${endpoint}`;
        try {
          const response = await axiosPrivate.get(url);

          setRecipeName(response.data.recipes[0].recipe_name);
          setRecipeDescription(response.data.recipes[0].recipe_descrip);
          setRecipeClass(response.data.recipes[0].recipe_class);
          setRecipeIntructions(response.data.recipes[0].recipe_intructions);
          setRecipeComments(response.data.recipes[0].recipe_comments);
          setRecipeRecursive(response.data.recipes[0].recipe_recursive);
          setNumberDoses(response.data.recipes[0].recipe_num_doses);
          setOrder(response.data.recipes[0].recipe_order);
          setRecipeStatus(response.data.recipes[0].recipe_status);
          setRecipeUsage(response.data.recipes[0].recipe_usage);
          setLoading(false);
        } catch (error) {
          Toaster.show("Erro ao buscar receita.", "error");
          setLoading(false);
        }
      };
      getRecipe("assign_list", { recipe_id: recipe_id });
    }
  }, [recipe_id]); // Include recipe_id in the dependency array to trigger useEffect on its change

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      recipeName === "" ||
      recipeClass === "" ||
      recipeDescription === "" ||
      recipeRecursive === ""
    ) {
      modalCloser(false);
      if (isNaN(numberDoses)) {
        Toaster.show("Número de doses deve ser um número.", "error");
        return;
      }
      Toaster.show(
        "Por favor, preencha os campos de nome, doses, classe, descrição e tipo.",
        "error"
      );
      return;
    }
    if (numberDoses < 1) {
      Toaster.show("Número de doses deve ser igual ou superior a 1.", "error");
      return;
    }
    setLoading(true);

    const data = {
      recipe_id: recipe_id,
      recipe_name: recipeName,
      recipe_descrip: recipeDescription,
      recipe_class: recipeClass,
      recipe_intructions: recipeIntructions,
      recipe_comments: recipeComments,
      recipe_recursive: recipeRecursive,
      recipe_num_doses: numberDoses,
      recipe_order: recipe_id === 0 ? -1 : recipeRecursive === "s" ? 0 : order,
      recipe_status: recipe_id === 0 ? "INCOMPLETE" : recipeStatus,
      recipe_usage: recipeUsage,
      //ao criar uma nova receita, o order é -1 e ao editar uma receita, passar para simples order fica 0 e para complexa fica o valor do order
    };
    try {
      const response = await axiosPrivate.post(
        `/recipe_create_or_update/`,
        JSON.stringify(data)
      );

      if (response.status === 200) {
        Toaster.show("Receita guardada com sucesso.", "success");
        setChange(!change);
        modalCloser(false)
      } else {
        Toaster.show("Erro ao guardar receita.", "error");
        setLoading(false);
        modalCloser(false)
      }
    } catch (error) {
      Toaster.show("Erro ao guardar receita.", "error");
      setLoading(false);
    }
  };
  const darkMode = useDarkMode();

  return (
    <div className="max-h-[50vh]">
      <div className="flex items-center justify-center ">
        <div >
          <div className="mb-4 flex">
            <div className="w-1/2 pr-2">
              <Label
                style={{ color: darkMode ? "white" : "black" }}
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Nome
              </Label>
              <input
                style={{ backgroundColor: darkMode ? "#262853" : "" }}
                className="w-full bg-gray-200 border rounded py-2 px-3"
                type="text"
                value={recipeName}
                onChange={(e) => setRecipeName(e.target.value)}
              />
            </div>
            <div className="w-1/2 pl-2">
              <Label
                style={{ color: darkMode ? "white" : "black" }}
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Classe
              </Label>
              <SelectComponent
                formatter={ClasseFormatter}
                darkMode={darkMode}
                onChange={setRecipeClass}
                value={recipeClass}
              />
            </div>
          </div>

          <InfoComponent text="">
            <p>
              Defina a quantidade de porções ou refeições que a receita pode
              produzir.
            </p>
          </InfoComponent>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Número de Doses
            </Label>
            <input
              style={{ backgroundColor: darkMode ? "#262853" : "" }}
              className="w-full bg-gray-200 border rounded py-2 px-3"
              type="number"
              min={1}
              value={numberDoses}
              onChange={(e) => setNumberDoses(Number(e.target.value))}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Descrição
            </Label>
            <textarea
              style={{ backgroundColor: darkMode ? "#262853" : "" }}
              className="w-full bg-gray-200 border rounded py-2 px-3"
              value={recipeDescription}
              onChange={(e) => setRecipeDescription(e.target.value)}
              maxLength={255}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Instruções
            </Label>
            <textarea
              style={{ backgroundColor: darkMode ? "#262853" : "" }}
              className="w-full bg-gray-200 border rounded py-2 px-3"
              value={recipeIntructions}
              onChange={(e) => setRecipeIntructions(e.target.value)}
              maxLength={255}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Comentário
            </Label>
            <textarea
              style={{ backgroundColor: darkMode ? "#262853" : "" }}
              className="w-full bg-gray-200 border rounded py-2 px-3"
              value={recipeComments}
              onChange={(e) => setRecipeComments(e.target.value)}
              maxLength={255}
            />
          </div>

          {recipe_id !== 0 && (
            <div className="mb-4">
              <Label
                style={{ color: darkMode ? "white" : "black" }}
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Estado
              </Label>
              <SelectComponent
                formatter={RecipeStatus}
                darkMode={darkMode}
                onChange={setRecipeStatus}
                value={recipeStatus}
              />
            </div>
          )}

          {/* 
          <InfoComponent text="">
            <div>
              <h3>Utilização da Receita</h3>
              <p>
                {" "}
                M-Receitas Menu - Produtos vendidos pelo restaurante
                confeccionados internamente.
              </p>
              <p>
                I-Receitas Internas - Componentes ou ingredientes que sejam
                usados em receitas (um molho bechamel, por exemplo) ou que sejam
                usados em porções como parte de um prato desde que sejam
                confeccionados internamente e portanto tenham uma receita (mas
                que não sejam vendidos directamente mas sim incorporados nos
                produtos vendidos pelo restaurante).
              </p>
              <p>
                O-Outras Receitas - Produtos que o Restaurante tem interesse em
                monitorizar o respectivo custo por diversas razões (pratos que
                podem vir a fazer parte do menu ou já fizeram e podem voltar a
                fazer, etc.)
              </p>
            </div>
          </InfoComponent>
          */}

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Utilização da Receita
            </Label>
            <SelectComponent
              formatter={RecipeUsage}
              darkMode={darkMode}
              onChange={setRecipeUsage}
              value={recipeUsage}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Tipo
            </Label>
            <select
              style={{ backgroundColor: darkMode ? "#262853" : "" }}
              className="w-full bg-gray-200 border rounded py-2 px-3"
              value={recipeRecursive}
              onChange={(e) => setRecipeRecursive(e.target.value)}
            >
              <option value="s">Receita Simples</option>
              <option value="c">Receita Complexa</option>
            </select>
            <InfoComponent text="">
              <p>
                Uma receita simples é constituída apenas por ingredientes. Uma
                receita complexa tem, na sua composição, uma ou mais
                sub-receitas.
              </p>
            </InfoComponent>
          </div>
          <div className="flex gap-6 w-full justify-end">
          <Button
            onClick={() => modalCloser(false)}
            type="submit"
            className="py-4 my-4 bg-red-400 hover:bg-red-500"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            type="submit"
            className="py-4 my-4"
          >
            Guardar receita
          </Button>
          </div>
        </div>
      </div>
      <Loading isOpen={recipe_id !== 0 && loading} />
    </div>
  );
};

export default RecipeForm;
