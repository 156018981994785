/**
 * v0 by Vercel.
 * @see https://v0.dev/t/XfYo91QDRK4
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Button } from "../../../../components/ui/button";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../../../../components/ui/table";
import { Badge } from "../../../../components/ui/badge";

import { useState, useEffect } from "react";
import { axiosPrivate } from "../../../../utils/api/axios";

export default function Subscription() {
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await axiosPrivate.get("/get-subscription-details/");
        setSubscriptionData(response.data);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    };

    fetchSubscriptionData();
  }, []);

  if (!subscriptionData) {
    return <div>Loading...</div>;
  }

  const { active_subscription, payment_method, billing_history } =
    subscriptionData;
  const { plan, current_period_end, amount } = active_subscription || {};

  return (
    <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8 h-[100vh]">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">Pagamentos</h1>
        <p className="text-muted-foreground">
          Gerencie suas assinaturas e detalhes de pagamento.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        <div className="bg-card p-6 rounded-lg shadow">
          <h2 className="text-xl font-bold mb-4">O Seu plano</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-muted-foreground">Plano</p>
              <p className="font-medium">
                {subscriptionData.product_name || "N/A"}
              </p>
            </div>
            <div>
              <p className="text-muted-foreground">Data de renovação</p>
              <p className="font-medium">
                {new Date(current_period_end * 1000).toLocaleDateString() ||
                  "N/A"}
              </p>
            </div>
            <div>
              <p className="text-muted-foreground">Preço</p>
              <p className="font-medium">
                {subscriptionData.product_price || "N/A"}€/mês
              </p>
            </div>
            <div>
              <p className="text-muted-foreground">Status</p>
              <p className="font-medium text-green-500">Ativo</p>
            </div>
          </div>
          <div className="mt-4">
            <Button variant="outline" size="sm">
              Alterar plano
            </Button>
            <Button variant="outline" size="sm" className="ml-2">
              Cancelar Subscrição
            </Button>
          </div>
        </div>
        <div className="bg-card p-6 rounded-lg shadow">
          <h2 className="text-xl font-bold mb-4">Método de Pagamento</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-muted-foreground">Tipo de Cartão</p>
              <p className="font-medium">
                {payment_method?.card?.brand || "N/A"}
              </p>
            </div>
            <div>
              <p className="text-muted-foreground">Número do Cartão</p>
              <p className="font-medium">
                **** **** **** {payment_method?.card?.last4}
              </p>
            </div>
            <div>
              <p className="text-muted-foreground">Data de Expiração</p>
              <p className="font-medium">
                {payment_method?.card?.exp_month}/
                {payment_method?.card?.exp_year}
              </p>
            </div>
            <div>
              <p className="text-muted-foreground">Endereço de Cobrança</p>
              <p className="font-medium">
                {payment_method?.billing_details?.address?.line1 || "N/A"}
              </p>
            </div>
          </div>
          <div className="mt-4">
            <Button variant="outline" size="sm">
              Atualizar Método de Pagamento
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">Histórico de Pagamentos</h2>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Fatura</TableHead>
              <TableHead>Data</TableHead>
              <TableHead>Valor</TableHead>
              <TableHead>Status</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {billing_history.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell>{invoice.number}</TableCell>
                <TableCell>
                  {new Date(invoice.created * 1000).toLocaleDateString()}
                </TableCell>
                <TableCell>{invoice.total / 100}€</TableCell>
                <TableCell>
                  <Badge variant="secondary">
                    {invoice.paid ? "Pago" : "Pendente"}
                  </Badge>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
