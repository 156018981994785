// React
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Hooks
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

// API
import { axiosPrivate } from "../api/axios";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const { auth } = useAuth();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      config => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          try {
            const newAccessToken = await refresh();
            if (prevRequest.url === "/auth/jwt/verify") {
              prevRequest.data = { token: newAccessToken };
            }
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            return axiosPrivate(prevRequest);
          } catch (refreshError) {
            console.error(
              "Error refreshing token (AxiosPrivate): ",
              refreshError
            );
            navigate("/");
            //window.location.reload();
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [refresh]);

  return axiosPrivate;
};

export const useApi = () => {
  const axiosPrivate = useAxiosPrivate();

  function getCacheKey(endpoint: string): string {
    const label = endpoint.replaceAll("/", "_");
    return label;
  }

  function setCache(endpoint: string, data: any): void {
    sessionStorage.setItem(getCacheKey(endpoint), JSON.stringify(data));
  }

  function getCache(endpoint: string): any {
    const cachedData = sessionStorage.getItem(getCacheKey(endpoint));
    return cachedData ? JSON.parse(cachedData) : null;
  }

  function clearCache(endpoint: string): void {
    sessionStorage.removeItem(getCacheKey(endpoint));
  }

  const get = async (url: string, config = {}) => {
    // const cachedData = getCache(url);
    // if (cachedData) {
    //     return cachedData;
    // }

    const response = await axiosPrivate.get(url, config);
    // setCache(url, response);
    return response;
  };

  const post = async (url: string, data: any, config = {}) => {
    return axiosPrivate.post(url, data, config);
  };

  const put = async (url: string, data: any, config = {}) => {
    return axiosPrivate.put(url, data, config);
  };

  const del = async (url: string, config = {}) => {
    return axiosPrivate.delete(url, config);
  };

  return { get, post, put, del, setCache, getCache, clearCache };
};

export default useAxiosPrivate;
