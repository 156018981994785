// React
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// contexts
import { DarkModeProvider } from "../../../utils/contexts/theme.context";
import { useLoading } from "../../../utils/contexts/LoadingContext";

// components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";
import Toaster from "../../../components/reusable/Toaster";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Button } from "../../../components/ui/button";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";

// icons / images
import { IoCart } from "react-icons/io5";
import { FaPencilAlt } from "react-icons/fa";
import { MdSearch } from "react-icons/md";

// hooks
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import "../../../assets/css/customScroll.styles.css"

interface Plan {
  an_id: string;
  an_nome: string;
  an_descr: string;
  an_obs: string;
  an_dt_ini: string;
  an_dt_fim: string;
  price_ini: number;
}

interface ShopListItem {
  recipe_id: string;
  recipe_name: string;
}

const PlanningCreate = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const navigate = useNavigate();

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "CREATE PLANNING PAGE",
  });

  const { get, post } = useApi();
  const [plans, setPlans] = useState<Plan[]>([]);
  const { loading, setLoading } = useLoading();
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalCreateInfo, setShowModalCreateInfo] = useState(false);
  const [inputValues, setInputValues] = useState<{
    [key: string]: { item: ShopListItem; num_doses: number };
  }>({});
  const [newPlan, setNewPlan] = useState({ name: "", description: "" });
  const [anId, setAnId] = useState("");
  const [shopList, setShopList] = useState<ShopListItem[]>([]);

  const darkMode = useDarkMode();

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    setLoading(true);
    try {
      const response = await get("list_plans");
      setPlans(response.data);
      console.log("Plans:", response.data);
    } catch (error) {
      console.error(error);
      Toaster.show("Erro ao carregar listas de planeamento", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    recipeId: string,
    value: { item: ShopListItem; num_doses: number }
  ) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [recipeId]: value,
    }));
  };

  const handleCreatePlan = async () => {
    setShowModalCreate(false);
    const newCustomer: Plan = {
      an_id: "", // Add an_id property with an empty string value
      an_nome: newPlan.name,
      an_descr: newPlan.description,
      an_obs: "Em progresso...",
      an_dt_ini: "Em progresso...",
      an_dt_fim: "Em progresso...",
      price_ini: 0,
    };
    setPlans((prevPlans) => [...prevPlans, newCustomer]);
    try {
      await post(
        `create_planning/?name=${newPlan.name}&description=${newPlan.description}`,
        {}
      );
    } catch (error) {
      console.error("Erro ao criar analise:", error);
    } finally {
      setLoading(false);
      fetchPlans();
      setNewPlan({ name: "", description: "" });
    }
  };

  const handleConfigurePlan = async (item: Plan) => {
    setAnId(item.an_id);
    setShowModalCreateInfo(true);
    setLoading(true);
    try {
      const response = await get("shopping_list");
      setShopList(response.data);
    } catch (error) {
      console.error("Erro buscar LISTA DE COMPRAS:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePlanCustom = async () => {
    setShowModalCreateInfo(false);
    const newPlanData = {
      name: newPlan.name,
      recipes: inputValues,
    };
    try {
      await post(`plan_create/?an_id=${anId}`, newPlanData);
    } catch (error) {
      console.error("Erro ao criar analise:", error);
    } finally {
      fetchPlans();
    }
  };

  const handleDisplayPlan = (plan: string) => {
    const match = plan.match(/\d+/);
    let result = match ? parseInt(match[0], 10) : 0;
    return `/app/planning/custom/${result}`;
  };

  const renderTable = (
    items: any[],
    columns: string[],
    renderRow: (item: any) => JSX.Element
  ) => (
    <Table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
      <TableHeader>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column}>{column}</TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>{items.map(renderRow)}</TableBody>
    </Table>
  );

  const renderModalContent = () => {
    return (
      <div className="overflow-auto">
        <div className="flex flex-col gap-10 px-1 mt-3">
          <div className="flex flex-col">
            <Label>Nome</Label>
            <Input
              type="text"
              placeholder="Nome do Planeamento"
              value={newPlan.name}
              onChange={(e) => setNewPlan({ ...newPlan, name: e.target.value })}
            />
          </div>
          <div className="flex flex-col">
            <Label>Descrição</Label>
            <textarea
              placeholder="Descrição do Planeamento"
              value={newPlan.description}
              onChange={(e) =>
                setNewPlan({
                  ...newPlan,
                  description: e.target.value,
                })
              }
              className="w-full px-4 pt-2 rounded-md border border-gray bg-white"
            />
          </div>
        </div>
        <div className="flex justify-end gap-x-3 mt-3">
          <Button
            className="cursor-pointer"
            onClick={handleCreatePlan}
            disabled={newPlan.name === "" || newPlan.description === ""}
          >
            Criar
          </Button>
        </div>
      </div>
    );
  };

  const renderModalContentConfigure = () => {
    return (
      <div >
        <div className="overflow-auto max-h-80 scroll pr-2">
          {shopList.length > 0 ? (
            renderTable(
              shopList,
              ["Nome da receita", "Número de doses"],
              (item: ShopListItem) => (
                <TableRow key={item.recipe_name}>
                  <TableCell
                    style={{ color: darkMode ? "white" : "black" }}
                    className="px-6 py-2 whitespace-no-wrap text-gray-600"
                  >
                    {item.recipe_name}
                  </TableCell>
                  <TableCell
                    style={{ color: darkMode ? "white" : "black" }}
                    className="px-6 py-2 whitespace-no-wrap text-gray-600"
                  >
                    <Input
                      type="text"
                      placeholder="Número de Doses"
                      value={
                        inputValues[item.recipe_id]?.["num_doses"] || ""
                      }

                      min={0}
                      onChange={(e) =>
                        handleInputChange(item.recipe_id, {
                          item,
                          num_doses: Number(e.target.value),
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <div
              style={{ color: darkMode ? "white" : "black" }}
              className="text-center text-2xl mt-10"
            >
              Sem Receitas disponíveis.
            </div>
          )}

        </div>
        <div className="flex justify-end gap-x-8 pt-5">
          <Button
            onClick={() => setShowModalCreateInfo(false)}
            className="cursor-pointer bg-red-400 hover:bg-red-500"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleCreatePlanCustom}
            className="cursor-pointer"
          >
            Criar
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Page title={"Criar Planeamento de Compras"} loading={loading}>
      {tokenVerified ? (
        <DarkModeProvider>
          <div className="w-[80%] mx-auto">
            <div className="flex justify-between items-center mb-5">
              <CardHeader>
                <CardTitle>Planeamento de Compras</CardTitle>
                <CardDescription>
                  Crie e gerencie seus planeamentos de compras
                </CardDescription>
              </CardHeader>
              <Dialog>
                <DialogTrigger asChild>
                  <Button className="mt-5">+ Planeamento</Button>
                </DialogTrigger>
                <DialogContent className="min-w-[700px] max-h-[800px] overflow-auto">
                  <DialogTitle>Criar Novo Planeamento</DialogTitle>
                  <DialogDescription>
                    Preencha os campos para criar um novo planeamento
                  </DialogDescription>
                  {renderModalContent()}
                </DialogContent>
              </Dialog>
            </div>
            <div>
              {plans.length > 0 ? (
                renderTable(
                  plans,
                  ["Nome", "Descritivo", "Estado", "Actions"],
                  (item: Plan) => (
                    <TableRow key={item.an_id}>
                      <TableCell
                        style={{ color: darkMode ? "white" : "black" }}
                        className="px-6 py-2 whitespace-no-wrap text-gray-600"
                      >
                        {item.an_nome}
                      </TableCell>
                      <TableCell
                        style={{ color: darkMode ? "white" : "black" }}
                        className="px-6 py-2 whitespace-no-wrap text-gray-600"
                      >
                        {item.an_descr}
                      </TableCell>
                      <TableCell
                        style={{ color: darkMode ? "white" : "black" }}
                        className="px-6 py-2 whitespace-no-wrap text-gray-600"
                      >
                        {item.an_obs.includes("disponivel")
                          ? "Disponível"
                          : "Por configurar"}
                      </TableCell>
                      <TableCell
                        style={{ color: darkMode ? "white" : "black" }}
                        className="px-6 py-2 whitespace-no-wrap text-gray-600"
                      >
                        {!item.an_obs.includes("disponivel on") ? (<div className="flex space-x-4">
                          {item.an_obs.includes("disponivel") ? (
                            <Dialog>
                              <DialogTrigger >
                                <Button
                                  className="cursor-pointer bg-transparent hover:bg-zinc-200"
                                  onClick={() => handleConfigurePlan(item)}
                                >
                                  <FaPencilAlt title="Configurar" color="black" size={22} />
                                </Button>
                              </DialogTrigger>
                              <DialogContent className="min-w-[700px] max-h-[800px] overflow-auto">
                                <DialogTitle>Configurar planeamento de compras</DialogTitle>
                                <DialogDescription>
                                  Selecione, para cada receita, o número de doses que deseja utilizar no planeamento.
                                </DialogDescription>
                                {renderModalContentConfigure()}
                              </DialogContent>
                            </Dialog>

                          ) : (
                            <div>
                              <p>Em processamento</p>
                            </div>
                          )}
                        </div>)
                          : (
                            <div>
                              <Button
                                className="cursor-pointer bg-transparent hover:bg-zinc-200"
                                onClick={() => navigate(handleDisplayPlan(item.an_obs))}
                              >
                                <MdSearch title="Ver Planeamento" color="black" size={22} />
                              </Button>
                            </div>
                          )}
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <div
                  style={{ color: darkMode ? "white" : "black" }}
                  className="text-center text-2xl mt-10"
                >
                  Sem planeamentos de compras. Crie um novo planeamento.
                </div>
              )}
            </div>
            <Loading isOpen={loading} />
          </div>
          <div className="flex justify-between items-center mb-5">

          </div>
          {/* <Modal isOpen={showModalCreateInfo} handler={null}>
            <h2
              style={{
                color: darkMode ? "white" : "black",
                paddingBottom: "20px",
                textAlign: "center",
              }}
            >
              Configurar planeamento de compras
            </h2>
            <div style={{ padding: "50px", paddingTop: "0px" }}>
              <div className="pb-5">
                <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
                  <thead className="bg-blue-dark text-white rounded">
                    <tr>
                      <th
                        style={{ background: darkMode ? "#422655" : "" }}
                        className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                      >
                        Nome da receita
                      </th>
                      <th
                        style={{ background: darkMode ? "#422655" : "" }}
                        className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                      >
                        Número de doses
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    style={{ backgroundColor: darkMode ? "#262853" : "" }}
                    className="bg-white divide-y divide-gray-200"
                  >
                    {shopList.map((item: any, index: any) => (
                      <tr key={index}>
                        <td
                          style={{ color: darkMode ? "white" : "black" }}
                          className="px-6 py-2 whitespace-no-wrap text-gray-600"
                        >
                          {item.recipe_name}
                        </td>
                        <td
                          style={{ color: darkMode ? "white" : "black" }}
                          className="px-6 py-2 whitespace-no-wrap text-gray-600"
                        >
                          <input
                            className="bg-white"
                            placeholder="0"
                            id="number"
                            type="number"
                            min={0}
                            value={
                              inputValues[item.recipe_id]?.["num_doses"] || ""
                            }
                            onChange={(e) =>
                              handleInputChange(item.recipe_id, {
                                item,
                                num_doses: Number(e.target.value),
                              })
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between gap-x-3 pt-5">
                <Button
                  onClick={handleCreatePlanCustom}
                  style={{ background: darkMode ? "#422655" : "" }}
                  className="w-full text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
                >
                  Criar
                </Button>
                <Button
                  onClick={() => setShowModalCreateInfo(false)}
                  style={{ background: darkMode ? "#422655" : "" }}
                  className="w-full text-white bg-error p-3 rounded hover:scale-110 transition-transform duration-300"
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Modal> */}
        </DarkModeProvider>
      ) : (
        // <Loading isOpen={true} />
<></>
      )}
    </Page>
  );
};

export default PlanningCreate;
