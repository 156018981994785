class WeeksFormatter {
    static weeks = [6, 7, 8, 9, 10, 11, 12];
    static default = 6;
  
    static format(input) {
      if (this.weeks.includes(input)) {
        return `${input} semanas`;
      } else {
        return null; // Return null or any other default value if the input is not valid
      }
    }
  
    static getAll() {
      return this.weeks;
    }
  }
  
  export default WeeksFormatter;
  