import { createContext, useContext, useState } from "react";

export interface DarkModeInterface {
  darkMode: boolean;
  setDarkMode?: (darkMode: boolean) => void
}

// themes.context.js

const DarkModeContext = createContext<DarkModeInterface>({ darkMode: true, setDarkMode: () => {}, });;

export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  return (
    <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => {
  const context = useContext(DarkModeContext);
  if (!context) {
    throw new Error("useDarkMode must be used within a DarkModeProvider");
  }
  return context;
};

