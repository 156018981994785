// React
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// Icons
import { FaGoogle, FaMicrosoft, FaApple } from "react-icons/fa";

// Utils
import axios from "../../utils/api/axios";
import { Input } from "../../components/ui/input";
import { useUser } from "../../utils/contexts/UserContext";
import BASE_URL from "../../utils/api/axios";
import useAuth from "../../utils/hooks/useAuth";
import Footer from "../../app/Footer";

const SignUp: React.FC = () => {
  const imageUrl = `${process.env.PUBLIC_URL}/logo3.png`;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { fetchUser } = useUser();
  const { setAuth } = useAuth();

  const handleEmailSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "signup",
        new URLSearchParams({
          username: username,
          password1: password,
          password2: confirmPassword,
        }).toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setAuth({ accessToken: response.data.access });
      await fetchUser();
      navigate("/app/homepage"); // aqui talvez se deve redirecionar para a pagina de login até para pedir verificacao de email? e nesse caso nao guardar token nem refresh?
    } catch (err) {
      setError("Sign up failed. Please try again.");
      console.error("Sign up error", err);
    }
  };

  const handleSocialSignUp = (provider: string) => {
    window.location.href = `${BASE_URL}/accounts/${provider}/login/?process=login`;
  };

  return (
    <div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="flex items-center justify-center h-full w-full p-4">
          <div className="bg-white py-6 px-20 rounded-lg shadow-lg w-full max-w-2xl">
            <div className="flex flex-col gap-3">
              <div className="flex justify-center">
                <img
                  className="h-16 rounded-full custom-color"
                  src={imageUrl}
                  alt="Logo"
                />
              </div>
              <Input
                type="text"
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
                className="mt-4"
              />
              <Input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Input
                type="password"
                placeholder="Confirme a sua Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                className="w-full bg-black hover:bg-gray-800 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleEmailSignUp}
              >
                Criar Conta
              </button>
            </div>
            {/* <p className="text-center mb-4 mt-8 font-semibold">
            Ou inscreva-se com
          </p>
          <div className="flex justify-center space-x-4">
            <button
              className="bg-black hover:bg-gray-800 text-white font-bold p-4 rounded-full focus:outline-none focus:shadow-outline"
              onClick={() => handleSocialSignUp("google")}
            >
              <FaGoogle className="w-7 h-7" />
            </button>
            <button
              className="bg-black hover:bg-gray-800 text-white font-bold p-4 rounded-full focus:outline-none focus:shadow-outline"
              onClick={() => handleSocialSignUp("microsoft")}
            >
              <FaMicrosoft className="w-7 h-7" />
            </button>
            <button
              className="bg-black hover:bg-gray-800 text-white font-bold p-4 rounded-full focus:outline-none focus:shadow-outline"
              onClick={() => handleSocialSignUp("apple")}
            >
              <FaApple className="w-7 h-7" />
            </button>
          </div> */}
            <p className="text-center mt-4">
              Já tem uma conta?{" "}
              <Link to="/" className="text-blue-500 hover:underline">
                Entrar
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
};

export default SignUp;
