// React
import { Label } from "../../ui/label";
import { Fragment, useEffect, useState } from "react";


// Components
import toast from "react-hot-toast";

// Icons
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
  const [emptyArgs, setEmptyArgs] = useState(true);
  const [status, setStatus] = useState({ loading: false, error: false });
  const [noMatch, setNoMatch] = useState(false);
  const [samePass, setSamePass] = useState(false);
  const [form, setForm] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(<FaEye size={32} />);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [passwordIconNew, setPasswordIconNew] = useState(<FaEye size={32} />);
  const [showPasswordConf, setShowPasswordConf] = useState(false);
  const [passwordIconConf, setPasswordIconConf] = useState(<FaEye size={32} />);

  //************ change password to be seen ***************/
  const togglePassword = () => {
    if (!showPassword) {
      setShowPassword(true);
    } else setShowPassword(false);
  };
  const changeIcon = () => {
    showPassword
      ? setPasswordIcon(<FaEye size={32} />)
      : setPasswordIcon(<FaEyeSlash size={32} />);
  };

  //************ change new password to be seen ***************/
  const togglePasswordNew = () => {
    if (!showPasswordNew) {
      setShowPasswordNew(true);
    } else setShowPasswordNew(false);
  };
  const changeIconNew = () => {
    showPassword
      ? setPasswordIconNew(<FaEye size={32} />)
      : setPasswordIconNew(<FaEyeSlash size={32} />);
  };

  //************ change password confirmation to be seen ***************/
  const togglePasswordConf = () => {
    if (!showPasswordConf) {
      setShowPasswordConf(true);
    } else setShowPasswordConf(false);
  };
  const changeIconConf = () => {
    showPasswordConf
      ? setPasswordIconConf(<FaEye size={32} />)
      : setPasswordIconConf(<FaEyeSlash size={32} />);
  };

  useEffect(() => {
    const checkPassword = () => {
      if (form.newPassword !== form.newPasswordConfirm) {
        setNoMatch(true);
      } else {
        setNoMatch(false);
      }
      if (form.oldPassword === form.newPassword) {
        setSamePass(true);
      } else {
        setSamePass(false);
      }
      if (
        form.oldPassword !== "" &&
        form.newPassword !== "" &&
        form.newPasswordConfirm !== ""
      ) {
        setEmptyArgs(false);
      } else {
        setEmptyArgs(true);
      }
    };
    checkPassword();
  }, [form.oldPassword, form.newPassword, form.newPasswordConfirm]);

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitChange = async () => {
    if (noMatch) {
      setStatus({ loading: false, error: true });
      throw new Error("passwords-do-not-match");
    } else if (form.oldPassword === "" || form.newPassword === "") {
      setStatus({ loading: false, error: true });
      throw new Error("empty-fields");
    } else if (samePass) {
      setStatus({ loading: false, error: true });
      throw new Error("old-password-cannot-be-same-as-new");
    }

    setStatus({ ...status, loading: true });

    try {
      //you have to reauthenticate the user in order for the update to work
      //await reauthenticateUser(form.oldPassword).then(() => {
      //changePassword(form.newPassword);
      //setStatus({ ...status, loading: false });
      //setShowToast(true);
      //});
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          toast.error("A sua password está errada.");
          break;
        default:
          toast.error(
            "De momento não foi possível alterar a sua password. Tente de novo mais tarde."
          );
      }
      setStatus({ loading: false, error: true });
    }
  };

  return (
    <>
      <Fragment>
        <div className="change-pwd-card">
          <div>
            <div>
              <div>
                {/****************** old ********************/}
                <Label  >Antiga password</Label>
                <input
                  name="oldPassword"
                  onChange={changeHandler}
                  type={showPassword ? "text" : "password"}
                />
                <button
                  className="eye-btn"
                  slot="end"
                  onClick={() => {
                    togglePassword();
                    changeIcon();
                  }}
                >
                  {passwordIcon}
                </button>
              </div>
              <div>
                {/****************** new ********************/}
                <Label  >Nova password</Label>
                <input
                  name="newPassword"
                  onChange={changeHandler}
                  type={showPasswordNew ? "text" : "password"}
                />
                <button
                  className="eye-btn"
                  slot="end"
                  onClick={() => {
                    togglePasswordNew();
                    changeIconNew();
                  }}
                >
                  {passwordIconNew}
                </button>
              </div>
              <div>
                {/****************** new confirmation ********************/}
                <Label  >Confirmar password</Label>
                <input
                  name="newPasswordConfirm"
                  onChange={changeHandler}
                  type={showPasswordConf ? "text" : "password"}
                />
                <button
                  className="eye-btn"
                  slot="end"
                  onClick={() => {
                    togglePasswordConf();
                    changeIconConf();
                  }}
                >
                  {passwordIconConf}
                </button>
              </div>
              {noMatch && (
                <p color="danger">
                  As passwords têm que ser iguais!
                </p>
              )}{" "}
              <br />
              {samePass && form.oldPassword !== "" && (
                <p color="danger">
                  A password nova não pode ser igual à antiga!
                </p>
              )}
            </div>
          </div>
          <button
            disabled={emptyArgs}
            className="change-pwd-btn"
            onClick={() => {
              submitChange();
            }}
          >
            Alterar
          </button>
        </div>
      </Fragment>
    </>
  );
};

export default ChangePassword;
