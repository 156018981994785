// React
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

// Icons
import { FaGoogle, FaMicrosoft, FaApple } from "react-icons/fa";

// Utils
import { validateToken, getNewAccessToken } from "../../utils/auth";
import axios from "../../utils/api/axios";
import useTokenVerifier from "../../utils/hooks/useTokenVerifier";
import { Input } from "../../components/ui/input";
import { useUser } from "../../utils/contexts/UserContext";
import useAuth from "../../utils/hooks/useAuth";
import BASE_URL from "../../utils/api/axios";
import Footer from "../../app/Footer";

const SignIn: React.FC = () => {
  const imageUrl = `${process.env.PUBLIC_URL}/logo3.png`;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { user, fetchUser } = useUser();
  const { auth, setAuth } = useAuth();

  // Destination path after login
  const from = location.state?.from?.pathname || "/app/homepage";

  useTokenVerifier({
    onTokenVerified: () => navigate(from),
    log: "LOGIN REFRESH",
  });

  const handleEmailSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "login",
        new URLSearchParams({
          username: username,
          password: password,
        }).toString(),
        {
          headers: {
            "X-CSRFToken": "",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setAuth({ accessToken: response.data.access });
      await fetchUser();
      //navigate("/app/homepage"); // not needed
    } catch (err) {
      setError("Sign in failed. Please try again.");
      console.error("Sign in error", err);
    }
  };
  //TODO mudar o url para usar a base que estiver ativa no utils/axios.tsx
  const handleSocialSignIn = (provider: string) => {
    window.location.href = `${BASE_URL}/accounts/${provider}/login/?process=login`;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("tokens");

    if (token) {
      handleTokenValidation(token);
    }

    const hasCompletedAuth = params.get("auth") === "complete";

    if (hasCompletedAuth) {
      handleSocialAuthCompletion();
    }
  }, [location]);

  const handleTokenValidation = async (token: string) => {
    const isValid = await validateToken(token);
    if (isValid) {
      localStorage.setItem("access_token", token);
      navigate("/dashboard");
    } else {
      setError("Invalid token. Please try logging in again.");
    }
  };

  // TODO logica de autenticacao tem de ser atualizada
  const handleSocialAuthCompletion = async () => {
    // access token nao e uma cookie, TODO
    const accessToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    const refreshToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)refresh_token\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );

    if (accessToken && refreshToken) {
      const isValid = await validateToken(accessToken);
      if (isValid) {
        //nao colocar nenhum token no local storage, TODO
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("refresh_token", refreshToken);
        try {
          const response = await axios.get("/api/user/");
          navigate("/dashboard");
        } catch (error) {
          console.error("Failed to fetch user data:", error);
          setError("Failed to fetch user data. Please try logging in again.");
        }
      } else {
        const newAccessToken = await getNewAccessToken(refreshToken);
        if (newAccessToken) {
          localStorage.setItem("access_token", newAccessToken);
          navigate("/dashboard");
        } else {
          setError("Authentication failed. Please try logging in again.");
        }
      }
    } else {
      setError("Authentication failed. Please try logging in again.");
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="flex items-center justify-center h-full w-full p-4">
          <div className="bg-white py-6 px-20 rounded-lg shadow-lg w-full max-w-2xl">
            <div className="flex flex-col gap-3">
              <div className="flex justify-center">
                <img
                  className="h-16 rounded-full custom-color"
                  src={imageUrl}
                  alt="Logo"
                />
              </div>
              <Input
                type="text"
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
                className="mt-4"
              />
              <Input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="w-full bg-black hover:bg-gray-800 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleEmailSignIn}
              >
                Entrar
              </button>
            </div>
            {/* <p className="text-center mb-4 mt-8 font-semibold">Ou entrar com</p>
          <div className="flex justify-center space-x-4">
            <button
              className="bg-black hover:bg-gray-800 text-white font-bold p-4 rounded-full focus:outline-none focus:shadow-outline"
              onClick={() => handleSocialSignIn("google")}
            >
              <FaGoogle className="w-7 h-7" />
            </button>
            <button
              className="bg-black hover:bg-gray-800 text-white font-bold p-4 rounded-full focus:outline-none focus:shadow-outline"
              onClick={() => handleSocialSignIn("microsoft")}
            >
              <FaMicrosoft className="w-7 h-7" />
            </button>
            <button
              className="bg-black hover:bg-gray-800 text-white font-bold p-4 rounded-full focus:outline-none focus:shadow-outline"
              onClick={() => handleSocialSignIn("apple")}
            >
              <FaApple className="w-7 h-7" />
            </button>
          </div> */}
            <p className="text-center mt-4">
              Não tem uma conta?{" "}
              <Link to="/signup" className="text-blue-500 hover:underline">
                Criar conta
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />

    </div>
  );
};

export default SignIn;
