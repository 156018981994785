import axios from "./api/axios";

export const validateToken = async (token: string) => {
  try {
    const response = await axios.post("/token/verify/", { token });
    return response.status === 200;
  } catch (error) {
    console.error("Token validation failed:", error);
    return false;
  }
};

export const getNewAccessToken = async (refreshToken: string) => {
  try {
    const response = await axios.post("/token/refresh/", {
      refresh: refreshToken,
    });
    return response.data.access;
  } catch (error) {
    console.error("Failed to refresh token:", error);
    return null;
  }
};
