import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";
import Toaster from "../../../../components/reusable/Toaster";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Button } from "../../../../components/ui/button";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

interface ProfileSetupProps {
  onPrevious?: () => void;
  onSubmit?: () => void;
}

const ProfileSetup: React.FC<ProfileSetupProps> = ({
  onPrevious,
  onSubmit,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { loading, setLoading } = useLoading();
  const [change, setChange] = useState(false);

  const [profile, setProfile] = useState<any>({});
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const getProfile = async () => {
      setLoading(true);
      try {
        const response = await axiosPrivate.get("profile");
        const profileData = Array.isArray(response.data)
          ? response.data[0]
          : response.data;
        setProfile(profileData);
        setFirst_name(profileData.first_name);
        setLast_name(profileData.last_name);
        setPhone(profileData.phone);
      } catch (error) {
        console.error("error", error);
      }
      setLoading(false);
    };

    getProfile();
  }, [change]);

  const isValidPhoneNumber = (number: string) => {
    const phoneNumber = parsePhoneNumberFromString(number, "PT");
    return phoneNumber ? phoneNumber.isValid() : false;
  };

  const handleProfileSubmit = async () => {
    setLoading(true);

    const data = {
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      setup: "COMPLETED",
    };

    if (!isValidPhoneNumber(phone)) {
      Toaster.show("Número de telemóvel inválido.", "error");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        "/profile-update/",
        JSON.stringify(data)
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Perfil atualizado com sucesso.", "success");
        if (onSubmit) {
          onSubmit();
        }
        setChange(!change);
      } else {
        Toaster.show("Erro ao atualizar perfil.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao atualizar perfil.", "error");
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="mt-8 space-y-10">
        <div>
          <Label htmlFor="first_name">Primeiro Nome</Label>
          <Input
            id="first_name"
            name="first_name"
            placeholder={profile.first_name}
            value={first_name}
            onChange={(e) => setFirst_name(e.target.value)}
            className="w-full"
          />
        </div>
        <div>
          <Label htmlFor="last_name">Último Nome</Label>
          <Input
            id="last_name"
            name="last_name"
            placeholder={profile.last_name}
            value={last_name}
            onChange={(e) => setLast_name(e.target.value)}
            className="w-full"
          />
        </div>
        <div>
          <Label htmlFor="phone">Número de Telemóvel</Label>
          <Input
            id="phone"
            name="phone"
            type="text"
            placeholder={profile.phone}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="w-full"
          />
        </div>
      </div>
      <div className="flex justify-between mt-5 mb-2">
        {onPrevious && <Button onClick={onPrevious}>Anterior</Button>}
        <Button
          onClick={handleProfileSubmit}
          className="ml-auto"
          disabled={loading || !first_name || !last_name || !phone}
        >
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default ProfileSetup;
