class CategoryFormatter {
  static categories = ["Peixe", "Carne"];

  static default = "Carne";

  static format(input: string): string {
    if (typeof input !== "string" || input === "") {
      return input;
    }
    const capitalized = input
      .split("")
      .map((char, index) => (index === 0 ? char.toUpperCase() : char))
      .join("");
    const replaced = capitalized.replace(/_/g, " e ");
    return replaced;
  }

  static getAll() {
    return this.categories;
  }

  static setAll(categories) {
    this.default = categories[0] || "";
    this.categories = categories;
  }
}
export default CategoryFormatter;
