// React
import React, { useEffect, useState, useCallback, useRef } from "react";

// contexts
import { useCat } from "../../../utils/contexts/CategoryContext";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../Profile/usedarkmode";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";

// components
import Page from "../page";
import ChartComponent from "../../../components/reusable/charts/chartComponent";
import ChartFunc from "../../../components/reusable/charts/chartFunc";
import MyTable from "../../../components/reusable/table/table-component";
import SelectComponent from "../../../components/reusable/select/select";
import Loading from "../../../components/reusable/loading/loading";
import NWeeksFormatter from "../../../components/reusable/select/nweeks";
import CategoryFormatter from "../../../components/reusable/select/categories";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { useLoading } from "../../../utils/contexts/LoadingContext";

// icons / images
import { FaChartBar } from "react-icons/fa";
import { IoBasket } from "react-icons/io5";
import { FaTable } from "react-icons/fa";


const INITIAL_CHART_STATE = {};
const INITIAL_CHART_FUNC_STATE = {
  func: "",
  option: {},
  drilldown_data: [],
};

const INITIAL_COLUMNS_Q22 = [
  { headerName: "Name", field: "name" },
  { headerName: "Price", field: "price" },
  { headerName: "Variation", field: "variation" },
];

const INITIAL_COLUMNS_Q42 = [
  { headerName: "Name", field: "name" },
  { headerName: "Category", field: "category" },
  { headerName: "Price", field: "price" },
  { headerName: "Variation", field: "variation" },
];

const OwnBasketPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "OWN BASKET VISION PAGE",
  });

  const darkMode = useDarkMode();
  const { get } = useApi();
  const categories = useCat();
  const [chartData, setChartData] = useState({
    q11: INITIAL_CHART_FUNC_STATE,
    q12: INITIAL_CHART_STATE,
    q21: INITIAL_CHART_STATE,
    q22: INITIAL_CHART_STATE,
    q31: INITIAL_CHART_STATE,
    q32: INITIAL_CHART_STATE,
    q41: INITIAL_CHART_STATE,
    q42: INITIAL_CHART_STATE,
  });

  const [nweeks, setNweeks] = useState(4);
  const [category, setCategory] = useState(categories.Categories[0] || "");
  const { loading, setLoading } = useLoading();;
  const [seeChart, setSeeChart] = useState(true);
  const chartRef = useRef(null);

  // Set initial category when categories are available
  useEffect(() => {
    if (categories.Categories.length > 0) {
      setCategory(categories.Categories[0]);
    }
  }, [categories]);

  const fetchChartData = useCallback(async (endpoint, params = {}) => {
    const queryString = new URLSearchParams(params).toString();
    const url = `dash2_v2_${endpoint}/${queryString ? `?${queryString}` : ""}`;

    try {
      setLoading(true);
      const response = await get(url);
      setChartData((prevData) => ({
        ...prevData,
        [endpoint]: response.data,
      }));
    } catch (error) {
      console.error(`Error fetching data for ${endpoint}:`, error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const endpoints = ["q11", "q12", "q21", "q22", "q31", "q32", "q41", "q42"];
    endpoints.forEach((endpoint) => {
      const params = endpoint.startsWith("q4")
        ? { nweeks, category }
        : { nweeks };
      fetchChartData(endpoint, params);
    });
  }, [nweeks, category, fetchChartData]);

  const renderChart = (ChartComponent, option, props = {}) => (
    <ChartComponent option={option} chartRef={chartRef} {...props} />
  );

  return (
    <Page title={""} loading={loading}>
      {tokenVerified ? (
        <div>
          {!loading && (<div>
            <div className="flex flex-inline justify-between mb-5">
              <CardHeader>
                <CardTitle>Visão Cabaz Próprio</CardTitle>
                <CardDescription>
                  Acompanhe os preços dos produtos utilizados no seu restaurante
                </CardDescription>
              </CardHeader>
              <div className="mt-5 mil:pt-0">
                <SelectComponent
                  formatter={NWeeksFormatter}
                  darkMode={darkMode}
                  onChange={setNweeks}
                />
              </div>
            </div>
            <div className="grid xl:grid-cols-2 gap-4 ">
              {renderChart(ChartFunc, chartData.q11.option, {
                func: chartData.q11.func,
                drilldown_data: chartData.q11.drilldown_data,
              })}

              {renderChart(ChartComponent, chartData.q12)}
            </div>
            <CardHeader className="mt-10 mb-5">
              <CardTitle>Análise por Categoria</CardTitle>
              <CardDescription>
                Variações de preço por categoria de produto do seu restaurante
              </CardDescription>
            </CardHeader>
            <div className="grid xl:grid-cols-2 gap-4 ">
              {renderChart(ChartComponent, chartData.q31)}
              {renderChart(ChartComponent, chartData.q32["chart_data"])}
            </div>

            <CardHeader className="mt-10">
              <CardTitle>Análise por Produto</CardTitle>
              <CardDescription>
                Saiba quais produtos do seu restaurante que mais variam de preço
              </CardDescription>
            </CardHeader>
            <div className="py-4 flex gap-6 items-center">
              <FaChartBar className={`cursor-pointer transition-all duration-300 ease-in-out`} color={seeChart ? "#484c9c" : "black"} onClick={() => setSeeChart(true)} size={36} title="Ver Gráfico" />
              <FaTable className={`cursor-pointer transition-all duration-300 ease-in-out`} color={!seeChart ? "#484c9c" : "black"} onClick={() => setSeeChart(false)} size={34} title="Ver Tabela" />
            </div>
            <div className="flex flex-col gap-4 ">
              {seeChart ?
                renderChart(ChartComponent, chartData.q21)
                :
                <MyTable
                  rowData={chartData.q22}
                  columnDefs={INITIAL_COLUMNS_Q22}
                  title="Produtos com maior variação do restaurante"
                  darkMode={darkMode}
                />
              }
            </div>

            {categories && categories.Categories.length > 0 && (
              <div>
                <div className="py-10 ">
                  <SelectComponent
                    formatter={CategoryFormatter}
                    darkMode={darkMode}
                    onChange={setCategory}
                    value={category}
                  />
                </div>
                <div className="flex flex-col gap-4 ">
                  {renderChart(ChartComponent, chartData.q41)}
                  <MyTable
                    rowData={chartData.q42}
                    columnDefs={INITIAL_COLUMNS_Q42}
                    title="Lista Top 15 de produtos da categoria com maior amplitude de preço do restaurante"
                    darkMode={darkMode}
                  />
                </div>
              </div>
            )}
          </div>)}
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default OwnBasketPage;
