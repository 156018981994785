// React
import { useState } from "react";

// Ionic
// (No Ionic imports in this selection)

// Hooks
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";

// Components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";
import UploadSAFTForm from "./upload/SaftList";

const UploadSAFTPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "UPLOAD SAFT PAGE",
  });
  return (
    <Page loading={false}>
      {tokenVerified ? <UploadSAFTForm /> : // <Loading isOpen={true} />
<></>}
    </Page>
  );
};

export default UploadSAFTPage;
