import React from "react";
import { cn } from "../../lib/utils";

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  variant?: "default" | "bold" | "light";
}

const labelVariants = {
  default: "text-sm font-medium text-gray-700 dark:text-gray-300",
  bold: "text-sm font-semibold text-gray-800 dark:text-gray-200",
  light: "text-sm font-normal text-gray-600 dark:text-gray-400",
};

const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ className, variant = "default", ...props }, ref) => {
    return (
      <label
        ref={ref}
        className={cn(
          labelVariants[variant],
          "block mb-1 transition-colors duration-200 ease-in-out ml-1",
          "peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
          className
        )}
        {...props}
      />
    );
  }
);

Label.displayName = "Label";

export { Label };
