class TypeFormatter {
  static types = ["Natural", "Receita", "Processados"];

  static default = "Natural";

  static format(input: string): string {
    return input;
  }

  static getAll() {
    return this.types;
  }

  static setAll(types) {
    this.types = types;
  }
}
export default TypeFormatter;
