import React from "react";
import { IoIosSearch } from "react-icons/io";


interface SearchBarProps {
  search: string;
  setSearch: (value: string) => void;
  setFetchSearch: (value: string) => void;
}



const SearchBar: React.FC<SearchBarProps> = ({ search, setSearch, setFetchSearch }) => {


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFetchSearch(search);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="bg-gray-200 rounded-full shadow flex px-2 py-1 w-fit">
        <input
          type="text"
          name="search"
          className="w-60 px-4 py-2 rounded-full transition-all duration-300 ease-in-out dark:text-gray-800 outline-none bg-transparent"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button type="submit">
          <div>
            <IoIosSearch className="text-zinc-500" size={36} />
          </div>
        </button>
      </div>
    </form>
  );
};

export default SearchBar;