// React
import { useState } from "react";

// Ionic
// (No Ionic imports in this selection)

// Hooks
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";

// Components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";

// Icons
import { MdCheckBox } from "react-icons/md";

const RestSelectProdsPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "RECIPES SELECT PAGE",
  });
  return (
    <Page>
      {tokenVerified ? <></> : // <Loading isOpen={true} />
<></>}
    </Page>
  );
};

export default RestSelectProdsPage;
