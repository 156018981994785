// React
import { useEffect } from "react";

// Hooks
import axiosPrivate from "../api/axios";
import useAuth from "./useAuth";
import { useUser } from "../../utils/contexts/UserContext";

const useTokenVerifier = ({ onTokenVerified, log }) => {
  const { auth } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    if (!auth?.accessToken) {
      return;
    }

    const controller = new AbortController();
    const AUTH_URL = "/auth/jwt/verify";
    const LOGS_URL = "/create_log";
    const verifyToken = async () => {
      try {
        const response = await axiosPrivate.post(
          AUTH_URL,
          {
            token: auth?.accessToken,
          },
          {
            signal: controller.signal,
          }
        );

        if (response.status < 300) {
          onTokenVerified();
          const logData = {
            username: user?.username,
            log_type: log,
            log_oper_status: "SUCCESS",
            log_oper_descr: "teste",
            log_obs: "localhost",
          };
          await axiosPrivate.post(
            LOGS_URL,
            logData,
            {
              headers: {
                Authorization: `Bearer ${auth?.accessToken}`,
              },
            }
          );
        }
      } catch (error) {
        console.error("Error refreshing token (tokenVerifier): ", error);
        /*const logData = {
          username: 'Anonymous',
          log_type: { log },
          log_oper_status: 'TOKEN INVALID',
          log_oper_descr: 'teste',
          log_obs: 'dummyObs',
        };
        const responseLogs = await axiosPrivate.post(LOGS_URL, logData);*/
        //history.goBack();
        //window.location.reload();
      }
    };

    verifyToken();

    return () => {
      controller.abort();
    };
  }, [user, auth?.accessToken]);
};

export default useTokenVerifier;
