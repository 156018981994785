class RecipeStatus {
  static status = ["IMPORTED", "IGNORED", "INCOMPLETE", "ACTIVE"];
  static default = "INCOMPLETE";

  static format(input: string): string {
    if (typeof input !== "string" || input === "") {
      return input;
    }
    const capitalized = input
      .split("")
      .map((char, index) => (index === 0 ? char.toUpperCase() : char))
      .join("");
    const replaced = capitalized.replace(/_/g, " e ");
    return replaced;
  }

  static getAll() {
    return this.status;
  }
}
export default RecipeStatus;
