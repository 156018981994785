import React, { useState } from "react";
import ProfileSetup from "./ProfileSetup";
import RestaurantSetup from "./RestaurantSetup";
import { useNavigate } from "react-router-dom";
import SuccessSetup from "./SuccessSetup";
import PricingTable from "../PricingTable";
import Footer from "../../../../app/Footer";

const stepTitles = {
  1: "Crie o seu Perfil",
  2: "Crie o seu Restaurante",
  3: "Escolha o seu plano",
  4: "Criação de Conta Concluída!",
};

const UserSetup: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const totalSteps = 3;

  const renderStep = () => {
    switch (step) {
      case 1:
        return <ProfileSetup onSubmit={handleNext} />;
      case 2:
        return (
          <RestaurantSetup onPrevious={handlePrevious} onSubmit={handleNext} />
        );
      case 3:
        return <PricingTable />;
      case 4:
        return <SuccessSetup />;
      default:
        return null;
    }
  };

  const handleNext = () => {
    if (step < totalSteps) setStep(step + 1);
  };

  const handlePrevious = () => {
    if (step > 1) setStep(step - 1);
  };

  return (
    <div>
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex items-center justify-center h-full w-full p-4">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
          <div className="mb-4">
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${(step / totalSteps) * 100}%` }}
              ></div>
            </div>
          </div>
          <div className="flex justify-between">
            <h2 className="text-2xl font-bold mb-4">{stepTitles[step]}</h2>
            <span className="text-sm text-gray-500">
              Passo {step} de {totalSteps}
            </span>
          </div>
          {renderStep()}
        </div>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default UserSetup;
