import useDarkMode from "../../pages/Profile/usedarkmode";

const CardComponent = ({
  title,
  value,
  variation,
  showVariation = true,
}: {
  title: string;
  value: string;
  variation?: number;
  showVariation?: boolean;
}) => {
  const darkMode = useDarkMode();

  return (
    <div
      className={`p-5 ${
        darkMode ? "bg-[#422653]" : "bg-white"
      } rounded-md mb-2`}
    >
      <div
        className={`text-base ${
          darkMode ? "text-gray-200" : " text-gray-400"
        } `}
      >
        {title}
      </div>
      <div className="flex items-center pt-1">
        <div
          className={`text-2xl font-bold ${
            darkMode ? "text-white" : " text-gray-900"
          } `}
        >
          {value}
        </div>
        {showVariation && (
          <span
            className={`flex items-center px-3 py-0.5 mx-2 text-sm rounded-full ${
              variation >= 0
                ? "text-green-600 bg-green-100"
                : "text-red-600 bg-red-100"
            } float-right ml-auto`}
          >
            <span>{variation}%</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default CardComponent;
