import React, { useState, useEffect } from 'react';

const Image = ({ src, alt, className }) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    let retryCount = 0;

    const loadImage = async () => {
      try {
        const response = await fetch(src);
        const blob = await response.blob();
        setImgSrc(URL.createObjectURL(blob));
      } catch (error) {
        retryCount++;
        if (retryCount <= 10) {
          loadImage();
        }
      }
    };

    loadImage();
  }, [src]);

  return (
    <img className={className} src={imgSrc} alt={alt} />
  );
};

export default Image;