// React
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Hooks
import { axiosPrivate } from "../utils/api/axios";
import { darkMode } from "../pages/Profile/Settings";

// Components
import Loading from "./reusable/loading/loading";
import Toaster from "./reusable/Toaster";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import ChangePassword from "../pages/Auth/ChangePassword";
import { Button } from "./ui/button";

// Icons
import {
  FaBook,
  FaClipboardList,
  FaHeadset,
  FaLock,
  FaSignOutAlt,
  FaUpload,
  FaUser,
} from "react-icons/fa";
import { Label } from "./ui/label";
import PricingTable from "../pages/App/user/PricingTable";
import { useLoading } from "../utils/contexts/LoadingContext";

const ProfileMenu = () => {
  const { loading, setLoading } = useLoading();
  const [showModal, setShowModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  const handleLogout = async () => {
    setLoading(true);
    try {
      await axiosPrivate.post("logout", {
        headers: { "Content-Type": "application/json" },
      });
      sessionStorage.clear();
      window.location.href = "/";
      setLoading(false);
    } catch (error) {
      console.error("Logout failed", error);
      setLoading(false);
    }
    
  };

  const handleSignOut = () => {
    handleLogout();
  };

  const handleImport = async (bool) => {
    setLoading(true);
    let url;
    bool ? (url = `/import_ingreds/`) : (url = `/import_recipes/`);

    try {
      const response = await axiosPrivate.post(url);

      if (response.status === 200 || response.status === 201) {
        Toaster.show(
          bool
            ? "Ingredientes importados com sucesso."
            : "Receitas importadas com sucesso.",
          "success"
        );
        setLoading(false);
      } else {
        Toaster.show(
          bool
            ? "Erro ao importar ingredientes. Por favor, tente mais tarde."
            : "Erro ao importar receitas. Por favor, tente mais tarde.",
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        bool
          ? "Erro ao importar ingredientes. Por favor, tente mais tarde."
          : "Erro ao importar receitas. Por favor, tente mais tarde.",
        "error"
      );
      setLoading(false);
    }
  };

  const handleChange = async () => {
    if (newPassword !== confirmPassword) {
      Toaster.show("Passwords não coincidem", "error");
      return;
    }

    const data = {
      current_password: oldPassword,
      new_password: newPassword,
      re_new_password: confirmPassword,
    };

    try {
      const response = await axiosPrivate.post(
        "set_password",
        JSON.stringify(data)
      );

      if (response.status === 200) {
        Toaster.show("Password alterada com sucesso!", "success");
        setLoading(false);
      } else {
        Toaster.show(response.data.message, "error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        "Erro ao alterar password. " + error.response.data.message,
        "error"
      );
      setLoading(false);
    }
    setShowModal(false);
  };

  const importIngredients = () => {
    return (
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Importar Ingredientes</DialogTitle>
          <DialogDescription className="pt-2">
            Estará a importar ingredientes para o menu com respetivos produtos
            de mercado associados. Deseja continuar?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="mt-3">
          <Button
            onClick={() => {
              handleImport(true);
              setShowModal(false);
            }}
          >
            Confirmar
          </Button>
          <Button
            className="bg-red-500 hover:bg-red-600"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button>
        </DialogFooter>
      </DialogContent>
    );
  };

  const importRecipes = () => {
    return (
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Importar Receitas</DialogTitle>
          <DialogDescription className="pt-2">
            Estará a importar receitas para o menu. Deseja continuar?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="mt-3">
          <Button
            onClick={() => {
              handleImport(false);
              setShowModal(false);
            }}
          >
            Confirmar
          </Button>
          <Button
            className="bg-red-500 hover:bg-red-600"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button>
        </DialogFooter>
      </DialogContent>
    );
  };

  return (
    <>
      <div className="bg-white py-2 px-2 gap-2 text-gray-700 text-sm">
        <Dialog>
          <DialogTrigger asChild>
            <button className="py-2 px-3 bg-white transition-all duration-300 hover:bg-gray-100 w-full text-left">
              <Link
                className={darkMode ? "text-white" : "text-gray-700"}
                to="/app/profile"
              >
                <FaUser className="inline mx-2" /> Meu Restaurante
              </Link>
            </button>
          </DialogTrigger>
        </Dialog>
        <hr className="bg-gray-400" />
        <Dialog>
          <DialogTrigger asChild>
            <button
              id="import_recipes"
              className="py-2 px-3 bg-white transition-all duration-300 hover:bg-gray-100 w-full text-left"
            >
              <Label className="cursor-pointer">
                <FaBook className="inline mx-2" /> Subscrição
              </Label>
            </button>
          </DialogTrigger>
          <DialogContent>
            <PricingTable />
          </DialogContent>
        </Dialog>
        <hr className="bg-gray-400" />
        <Dialog>
          <DialogTrigger asChild>
            <button className="py-2 px-3 bg-white transition-all duration-300 hover:bg-gray-100 w-full text-left">
              <Label className="cursor-pointer">
                <FaUpload className="inline mx-2" /> Importar Ingredientes
              </Label>
            </button>
          </DialogTrigger>
          {importIngredients()}
        </Dialog>
        <hr className="bg-gray-400" />
        <Dialog>
          <DialogTrigger asChild>
            <button
              id="import_recipes"
              className="py-2 px-3 bg-white transition-all duration-300 hover:bg-gray-100 w-full text-left"
            >
              <Label className="cursor-pointer">
                <FaBook className="inline mx-2" /> Importar Receitas
              </Label>
            </button>
          </DialogTrigger>
          {importRecipes()}
        </Dialog>
        <hr className="bg-gray-400" />
        <button className="py-2 px-3 bg-white transition-all duration-300 hover:bg-gray-100 w-full text-left">
          <Link
            className={darkMode ? "text-white" : "text-gray-700"}
            to="/app/menu/rest-select-prods"
          >
            <FaClipboardList className="inline mx-2" /> Escolher Receitas
          </Link>
        </button>
        <hr className="bg-gray-400" />
        <button className="py-2 px-3 bg-white transition-all duration-300 hover:bg-gray-100 w-full text-left">
          <Link
            className={darkMode ? "text-white" : "text-gray-700"}
            to="/app/tickets"
          >
            <FaHeadset className="inline mx-2" /> Suporte
          </Link>
        </button>
        <hr className="bg-gray-400" />
        <Dialog>
          <DialogTrigger asChild>
            <button className="py-2 px-3 bg-white transition-all duration-300 hover:bg-gray-100 w-full text-left">
              <Label className="cursor-pointer">
                <FaLock className="inline mx-2" /> Mudar Password
              </Label>
            </button>
          </DialogTrigger>
          <DialogContent>
            <ChangePassword />
          </DialogContent>
        </Dialog>
        <hr className="bg-gray-400" />
        <button
          className="py-2 px-3 bg-white transition-all duration-300 hover:bg-gray-100 w-full text-left"
          onClick={() => {
            handleSignOut();
          }}
        >
          <Label className="cursor-pointer">
            <FaSignOutAlt className="inline mx-2" /> Terminar Sessão
          </Label>
        </button>
      </div>
    </>
  );
};

export default ProfileMenu;
