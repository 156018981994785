"use client"

import * as React from "react"
import { DayPicker } from "react-day-picker"
import "react-day-picker/style.css";

import { cn } from "../../lib/utils";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
    disabledDate?: (date: Date) => boolean;
}

function Calendar({
    className,
    classNames,
    showOutsideDays = false,
    disabledDate,
    ...props
}: CalendarProps) {
    return (
        <DayPicker
            disabled={(date) =>
                (disabledDate && disabledDate(date)) || date < new Date("2022-01-01")
            }
            showOutsideDays={showOutsideDays}
            className={cn("p-2", className)}
            {...props}
        />
    )
}
Calendar.displayName = "Calendar"

export { Calendar }