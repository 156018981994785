import {
  CardTitle,
  CardDescription,
  CardHeader,
} from "../../../../components/ui/card";
import { Button } from "../../../../components/ui/button";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../../utils/contexts/UserContext";
import { useEffect } from "react";

const SuccessSetup = () => {
  const { fetchUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="text-center">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mt-5">
        <div onClick={() => navigate("/app/general")}>
          <img
            src={`${process.env.PUBLIC_URL}/home_market.png`}
            alt="Evolução de Preços"
            className="w-full h-full object-cover rounded-t-2xl mx-auto"
            style={{ width: "70%", height: "70%" }} // Reduced size by 30%
          />
          <CardHeader className="mt-5">
            <CardTitle className="text-2xl">Evolução de Preços</CardTitle>
            <CardDescription className="text-sm">
              Acompanhe a evolução dos preços dos produtos
            </CardDescription>
          </CardHeader>
        </div>
        <div onClick={() => navigate("/app/sales/short")}>
          <img
            src={`${process.env.PUBLIC_URL}/home_sales.png`}
            alt="Análise de Vendas"
            className="relative w-full h-full object-cover rounded-t-2xl mx-auto"
            style={{ width: "70%", height: "70%" }} // Reduced size by 30%
          />
          <CardHeader className="mt-5">
            <CardTitle className="text-2xl">Análise de Vendas</CardTitle>
            <CardDescription className="text-sm">
              Acompanhe a evolução das vendas dos seus produtos
            </CardDescription>
          </CardHeader>
        </div>
        <div onClick={() => navigate("app/customers")}>
          <img
            src={`${process.env.PUBLIC_URL}/home_customer.png`}
            alt="Análise de Consumidores"
            className="w-full h-full object-cover rounded-t-2xl mx-auto"
            style={{ width: "70%", height: "70%" }} // Reduced size by 30%
          />
          <CardHeader className="mt-5">
            <CardTitle className="text-2xl">Análise de Consumidores</CardTitle>
            <CardDescription className="text-sm">
              Explore o comportamento dos seus clientes
            </CardDescription>
          </CardHeader>
        </div>
        <div onClick={() => navigate("/app/forecasts")}>
          <img
            src={`${process.env.PUBLIC_URL}/home_forecast.png`}
            alt="Previsão de Vendas"
            className="w-full h-full object-cover rounded-t-2xl mx-auto"
            style={{ width: "70%", height: "70%" }} // Reduced size by 30%
          />
          <CardHeader className="mt-5">
            <CardTitle className="text-2xl">Previsão de Vendas</CardTitle>
            <CardDescription className="text-sm">
              Faça a previsão de vendas com base nos seus dados
            </CardDescription>
          </CardHeader>
        </div>
      </div>
      <Button
        onClick={() => navigate("/app/homepage")}
        className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors"
      >
        Explore o AI4CHEF
      </Button>
    </div>
  );
};

export default SuccessSetup;
