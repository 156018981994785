// React
// (No React imports needed)



// Hooks
// (No Hooks imports needed)

// Components
import ChangePassword from "../../components/Auth/change-password/change-password.component";

// Icons
// (No Icons imports needed)

const ChangePasswordPage = () => {
  return (
    <div>
      <div>
        <div>
          <div slot="start">
            {/* <div text="Voltar" defaultHref="/app/profile" /> */}
          </div>
          <div className="change-pwd-title">
            Alterar Palavra-chave
          </div>
        </div>
      </div>
      <div className="change-pwd-content">
        <ChangePassword />
      </div>
    </div>
  );
};

export default ChangePasswordPage;
