import React, { useState, useEffect } from "react";

// Hooks
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";
import { useUser } from "../../../../utils/contexts/UserContext";

// Components
import InfoComponent from "../../../../components/reusable/info/info.component";
import Toaster from "../../../../components/reusable/Toaster";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

// Icons
import { IoAdd, IoTrash } from "react-icons/io5";

interface CategoriesSetupProps {
  onPrevious?: () => void;
  onSubmit?: () => void;
}

const CategoriesSetup: React.FC<CategoriesSetupProps> = ({
  onPrevious,
  onSubmit,
}) => {
  const { user } = useUser();
  const axiosPrivate = useAxiosPrivate();
  const darkMode = useDarkMode();
  const [data, setData] = useState([]);
  const { loading, setLoading } = useLoading();
  const [modalCreate, setModalVisibleCreate] = useState(false);
  const [changedList, setChangedList] = useState(false);
  const [prod_category, setProd_category] = useState("");
  const [prod_category_info, setProd_category_info] = useState("");

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    // Get categories from the backend
    const getCategories = async () => {
      try {
        const response = await axiosPrivate.get("list-prod-categories", {
          signal: controller.signal,
        });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getCategories();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [changedList]);

  // Delete category from the backend
  const deleteCategory = async (prod_category_id: string) => {
    const response = await axiosPrivate.post(
      `/delete-prod-categories/`,
      JSON.stringify({ prod_category_id: prod_category_id })
    );

    if (response.status === 200) {
      Toaster.show("Categoria apagada com sucesso.", "success");
      setLoading(true);
      setChangedList(!changedList);
    } else {
      Toaster.show("Erro ao apagar categoria.", "error");
      setLoading(false);
    }
  };

  // Create category from the backend
  const handleSubmit = async () => {
    if (prod_category === "") {
      Toaster.show("Preencha os campos.", "error");
      return;
    }

    const data = {
      prod_category: prod_category,
      prod_category_info: prod_category_info,
    };
    try {
      const response = await axiosPrivate.post(
        `/create-prod-categories/`,
        JSON.stringify(data)
      );

      if (response.status === 201) {
        setLoading(true);
        setChangedList(!changedList);
        Toaster.show("Receita guardada com sucesso.", "success");
      } else {
        Toaster.show("Erro ao adicionar categoria.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao adicionar categoria.", "error");
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await axiosPrivate.post(`/rest_prod_apply_changes/`, {});

      if (response.status === 200) {
        Toaster.show("Categorias atualizadas com sucesso.", "success");
      } else {
        Toaster.show("Erro ao atualizar categorias.", "error");
      }
    } catch (error) {
      console.log(error);
      Toaster.show("Erro ao atualizar categorias.", "error");
    }
  };

  return (
    <div>
      {!loading && (
        <div className="">
          <div className="flex items-center space-x-4 mt-auto justify-end">
            <Button onClick={() => setModalVisibleCreate(true)}>
              <IoAdd size={16} className="ml-auto" />
              Categoria
            </Button>
            {user.profile && (
              <Button onClick={handleUpdate}>Reclassificar produtos</Button>
            )}
          </div>
          <Table className="mt-4">
            <TableHeader>
              <TableRow>
                <TableHead>Nome</TableHead>
                <TableHead>Informação</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.prod_category}</TableCell>
                  <TableCell>{item.prod_category_info}</TableCell>
                  <TableCell>
                    <IoTrash
                      size={18}
                      onClick={() => deleteCategory(item.prod_category_id)}
                      className="text-red-500 text-2xl hover:scale-125 transition-transform duration-300 ml-auto"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <InfoComponent text="">
            <div>
              <p>
                Defina as classes de produtos do seu menu. Deste modo terá
                análises focadas para cada uma das suas classes.
              </p>
              <p>
                Quando terminar as alterações, sejam elas uma remoções ou
                adições, deverá clicar em reclassificar produtos.
              </p>
            </div>
          </InfoComponent>
          <div className="flex justify-between mt-5 mb-2">
            {onPrevious && <Button onClick={onPrevious}>Anterior</Button>}
            <Button onClick={onSubmit} className="ml-auto" disabled={loading}>
              Guardar
            </Button>
          </div>
        </div>
      )}
      {modalCreate && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-center">Nova Classe</h2>
            <div className="mb-4">
              <Input
                value={prod_category}
                onChange={(e) => setProd_category(e.target.value)}
                className="w-full bg-gray-200 border rounded py-2 px-3"
              />
            </div>
            <div className="flex justify-center">
              <Button
                onClick={() => {
                  setModalVisibleCreate(false);
                  handleSubmit();
                }}
                className="bg-blue-900 p-3 hover:bg-blue-700 text-white font-bold rounded focus:outline-none focus:shadow-outline"
              >
                Submeter Categoria
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoriesSetup;
