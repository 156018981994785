import React from "react";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <footer className="app-footer bg-zinc-100 py-6">
      <ul>
        <li>
          <a href="/politicaprivacidade.html" target="_blank" rel="noopener noreferrer">
            Política de Privacidade
          </a>
        </li>
        <li>
          <a href="/termosservico.html" target="_blank" rel="noopener noreferrer">
            Termos de Serviço
          </a>
        </li>
        <li>
          <a href="/politicacancelamentoreembolso.html" target="_blank" rel="noopener noreferrer">
            Política de Cancelamento e Reembolso
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
