
import { duration } from 'html2canvas/dist/types/css/property-descriptors/duration';
import { toast, ToastOptions } from 'react-hot-toast';

type ToastType = 'success' | 'error' | 'loading' | 'custom';

class CustomToast {
  private static instance: CustomToast;

  private constructor() { }

  public static getInstance(): CustomToast {
    if (!CustomToast.instance) {
      CustomToast.instance = new CustomToast();
    }
    return CustomToast.instance;
  }

  public show(message: string, type: ToastType, options?: ToastOptions): void {
    toast.dismiss(); // Dismiss all existing toasts

    const toastOptions: ToastOptions = {
      duration: 5000, // Set default duration to 5 seconds
      ...options,
    };

    switch (type) {
      case 'success':
        toast.success(message, toastOptions);
        break;
      case 'error':
        toast.error(message, toastOptions);
        break;
      case 'loading':
        toast.loading(message, toastOptions);
        break;
      case 'custom':
        toast(message, toastOptions);
        break;
      default:
        toast(message, toastOptions);
    }
  }

  public showWithHandler(message: string, handler: () => Promise<void>, options?: ToastOptions): void {
    toast.dismiss(); // Dismiss all existing toasts

    const toastOptions: ToastOptions = {
      duration: Infinity,
      ...options,
    };

    toast((t) => (
      <div className='flex flex-col gap-4'>
        <p>{message}</p>
        <div className='flex gap-4 w-full justify-end '>
          <button  className='bg-zinc-200 hover:bg-zinc-300 rounded-xl transition-all ease-in-out duration-500 text-black py-2 px-4'
            onClick={() => {
              handler()
                .then(() => {
                  toast.dismiss(t.id);
                })
                .catch(() => {
                  toast.dismiss(t.id);
                });
            }}
          >
            Confirmar
          </button>
          <button  className='bg-red-400 hover:bg-red-500 rounded-xl transition-all ease-in-out duration-500 text-white py-2 px-4' onClick={() => toast.dismiss(t.id)}>Cancelar</button>
        </div>
      </div>
    ), toastOptions);
  }

  public dismiss(): void {
    toast.dismiss();
  }
}

export default CustomToast.getInstance();